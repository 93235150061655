import React, { useState } from "react";
import classNames from "classnames";
import SimpleBar from "simplebar-react";

import menudata from "./MenuData";
import Toggle from "./Toggle";
import LogoLight2x from '../../assets/images/mag_brand_logo.png';
import LogoDark2x from '../../assets/images/mag_brand_logo.png';
import { Link } from "react-router-dom";
import Menu from './Menu';

const Sidebar = ({ fixed, theme, className, sidebarToggle, mobileView, ...props }) => {
    const [collapseSidebar, setSidebar] = useState(false);
    const [mouseEnter, setMouseEnter] = useState(false);

    const toggleCollapse = () => {
        setSidebar(!collapseSidebar);
    };

    const handleMouseEnter = () => setMouseEnter(true);
    const handleMouseLeave = () => setMouseEnter(false);

    const classes = classNames({
        "nk-sidebar": true,
        "nk-sidebar-fixed": fixed,
        "is-compact": collapseSidebar,
        "has-hover": collapseSidebar && mouseEnter,
        [`is-light`]: theme === "white",
        [`is-${theme}`]: theme !== "white" && theme !== "light",
        [`${className}`]: className,
    });

    return (
        <div className={classes}>
            <div className="nk-sidebar-element nk-sidebar-head">
                <div className="nk-menu-trigger">
                    <Toggle className="nk-nav-toggle nk-quick-nav-icon d-xl-none mr-n2" icon="arrow-left" click={sidebarToggle} />
                    <Toggle
                        className={`nk-nav-compact nk-quick-nav-icon d-none d-xl-inline-flex ${collapseSidebar ? "compact-active" : ""
                            }`}
                        click={toggleCollapse}
                        icon="menu"
                    />
                </div>
                <div className="nk-sidebar-brand">
                    <Link to={`${process.env.PUBLIC_URL}/`} className="logo-link">

                        <img className="logo-light logo-img" src={LogoLight2x} alt="logo" />
                        <img className="logo-dark logo-img" src={LogoDark2x} alt="logo" />

                    </Link>
                </div>
            </div>
            <div className="nk-sidebar-content" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                <SimpleBar className="nk-sidebar-menu">
                    {/* <Menu sidebarToggle={sidebarToggle} mobileView={mobileView}/> */}
                    <Menu sidebarToggle={sidebarToggle} mobileView={mobileView} menudata={menudata} />

                </SimpleBar>
            </div>
        </div>
    );
};
export default Sidebar;
