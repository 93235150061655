import React from "react";
import { Radar } from "react-chartjs-2";

const getChartData = (canvas) => {
  const ctx = canvas.getContext("2d");

  const x = canvas.height * 0.65;
  const y = canvas.width * 0.25;
  const outerRadius = canvas.width / 3.2;

  const x1 = x * 1.49;
  const y1 = y * 0.87;

  const gradient = ctx.createRadialGradient(x, y, outerRadius, x1, y1, 1);
  gradient.addColorStop(0, "blue");
  gradient.addColorStop(1, "white");
  return {
    labels: [
      "Javascript",
      "React",
      "CSS",
      "HTML",
      "Webpack",
      "Problem Solving",
    ],
    datasets: [
      {
        label: "skillset",
        borderWidth: 0.5,
        backgroundColor: gradient,
        borderColor: "blue",
        lineTension: 0.4,
        pointRadius: 0,

        //data: [25, 50, 75, 91, 100, 50]
      },
    ],
  };
};

export const RadarData = {
  labels: [
    "Global",
    "Trading System",
    "Rule Violation",
    "Communication",
    "IT",
    "Regulatory",
    "Quality",
  ],
  datasets: [
    {
      label: "Score",
      backgroundColor: "rgba(34, 202, 236, .2)",
      borderColor: "rgba(34, 202, 236, 1)",
      pointBackgroundColor: "rgba(34, 202, 236, 1)",
      poingBorderColor: "#fff",
      pointHoverBackgroundColor: "#fff",
      pointHoverBorderColor: "rgba(34, 202, 236, 1)",
    },
  ],
};

const options = {
  scale: {
    ticks: {
      min: 0,
      max: 100,
      stepSize: 10,
      showLabelBackdrop: false,
      backdropColor: "rgba(203, 197, 11, 1)",
    },
    angleLines: {
      color: "rgba(255, 255, 255, .3)",
      lineWidth: 1,
    },
    gridLines: {
      color: "rgba(255, 255, 255, .3)",
      circular: true,
    },
  },
};

const optionsold = {
  responsive: true,
  maintainAspectRatio: true,
  layout: {
    beginAtZero: true,
    padding: {
      right: 35,
      bottom: 60,
    },
  },
  scale: {
    gridLines: {
      circular: true,
    },
  },
};

//Max und Min Scores:
//    - Global Score min 1 max 3
//- Trading System Score
//Rule Violation Score
//Communication Score  0 bis 1 faktor 100 anwenden
//IT Score 0.5 bis 1 faktor 100 anwenden
//Regulatory Score 0 bis 1 faktor 100 anwenden
//System Quality Score  min 0 max 100 -> keine Anpassung notwendig

export default function RadarChartTrader(data) {
  const RadarDatanew = {
    labels: [
      "Rule Violation",
      "Trading System Quality",
      "Communication",
      "IT",
      "Regulatory",
      "Trading System Score",
    ],
    datasets: [
      {
        label: "Score",
        backgroundColor: "rgba(34, 202, 236, .2)",
        borderColor: "rgba(34, 202, 236, 1)",
        pointBackgroundColor: "rgba(34, 202, 236, 1)",
        poingBorderColor: "#fff",
        pointHoverBackgroundColor: "#fff",
        pointHoverBorderColor: "rgba(34, 202, 236, 1)",
        data: data.data,
        //data: localStorage.getItem("radar_data")
      },
    ],
  };

  return (
    //<Radar data={(canvas) => getChartData(canvas)} options={options} />
    <Radar data={RadarDatanew} options={options} />
  );
}
