import React, { useState, useEffect } from 'react';
import axios from "../../../../api/axios";
import {Alert} from "reactstrap";
import Icon from "../../../../assets/components/icon/Icon";
import DatePicker from "react-datepicker";
import moment from 'moment';
import ReturnCalculator_Table from "./ReturnCalculator_Table";

const RETURN_CALCULATOR_API = '/api/rtm/return_calculation/';


const Form_Return_Calculator = () => {
    const [errMsg, setErrMsg] = useState('');
    const [portfolionames, setportfolioNames] = useState(JSON.parse(localStorage.getItem("portfolios")));
    const [accountnames, setaccountnames] = useState(JSON.parse(localStorage.getItem("accounts")));
    const [accounts, setaccounts] = useState([]);
    const [accountsselected, setaccountsselected] = useState(Object.keys(portfolionames)[0]);
    const [startDate, setStartDate] = useState(new Date('2022-01-01'));
    const [endDate, setEndDate] = useState(new Date('2022-10-23'));
    const [performancefee, setperformancefee] = useState('0');
    const [startingbalance, setstartingbalance] = useState('0');
    const [pastcalculations, setpastcalculations] = useState([]);


    const sendcalculation_api = async (e) => {
        e.preventDefault();
        console.log(accountsselected);
        console.log(moment(startDate).format( "YYYY-MM-DD"));


        let portfolio_id_tmp = 0;
        let account_id_tmp = 0;
        if (accountsselected.toString().length < 3)
        {
            portfolio_id_tmp = accountsselected;
        }else
        {
            account_id_tmp = accountsselected;
        }

        var token = "";
        if (typeof window !== 'undefined') {
            token = localStorage.getItem('Token');
        }
        const response = await axios.post(RETURN_CALCULATOR_API, {
                "trading_account_id": account_id_tmp,
                "portfolio_id": portfolio_id_tmp,
                "pf_settings": performancefee,
                "start_balance": startingbalance,
                "start_date": moment(startDate).format( "YYYY-MM-DD"),
                "end_date": moment(endDate).format( "YYYY-MM-DD")
            },
            {headers: {
                    'Content-Type': 'application/json',
                    'Authorization': ['Bearer ', token].join('')
                }});

        console.log(await response);
        var tmp_pastcalculations = pastcalculations.concat(await response?.data);
        let x = tmp_pastcalculations.sort((a, b) => (a.calc_id < b.calc_id ? 1 : -1));
        setpastcalculations(x);

    }

    useEffect(() => {

        let tmp_acc = {};
        let temp_dropdownusers = [];

        for(const [key, val] of Object.entries(portfolionames)) {
            temp_dropdownusers.push({
                "id": key,
                "name": "Portfolio " + key.toString() +" - " + val,
            })
        }
        for(const [key, val] of Object.entries(accountnames)) {
            temp_dropdownusers.push({
                "id": key,
                "name": "Account " + key.toString() +" - " + val,
            })
        }

        async function fetchReturnCalcs() {
            try {
                var token = "";
                if (typeof window !== 'undefined') {
                    token = localStorage.getItem('Token');
                }
                const response = await axios.get(RETURN_CALCULATOR_API, {headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Authorization': ['Bearer ', token].join('')
                    }});

                setpastcalculations(await response?.data);
            }
            catch (e)
            {
                console.log(e);
            }
        }


        fetchReturnCalcs();
        setaccounts(temp_dropdownusers);

    }, [])

    return(
        <React.Fragment>
            <div className="card-inner">
                <div className="card-title-group">
                    <div className="card-title card-title-sm">
                        <h6 className="title">Performance Calculator</h6>
                    </div>
                </div>
            </div>


            <div className="card-inner">
                <form action="#" onSubmit={sendcalculation_api}>
                    <div className="row g-4">
                        <div className="col-lg-6">
                            <div className="form-group"><label className="form-label" htmlFor="full-name-1">Choose Account or Portfolio</label>
                                <div className="form-control-wrap">

                                    <select name="alertype" className="form-control" id="alertype" onChange={(e) => setaccountsselected(e.target.value)}>
                                        {accounts.map((key) => {
                                            return <option key={key.id} value={key.id}>{key.name}</option>
                                        })}
                                    </select>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group"><label className="form-label" htmlFor="email-address-1">Starting Balance</label>
                                <div className="form-control-wrap">
                                    <input type="number" className="form-control"
                                           id="starting_balance_id" onChange={(e) => setstartingbalance(e.target.value)}/>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6">
                            <div className="form-group"><label className="form-label" htmlFor="pay-amount-1">Start Date</label>
                                <div className="form-control-wrap"><DatePicker dateFormat="yyyy-MM-dd" selected={startDate} onChange={(date) => setStartDate(date)} /></div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group"><label className="form-label" htmlFor="pay-amount-1">End Date</label>
                                <div className="form-control-wrap"><DatePicker dateFormat="yyyy-MM-dd" selected={endDate} onChange={(date) => setEndDate(date)} /></div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group"><label className="form-label" htmlFor="phone-no-1">Performance Fee %</label>
                                <div className="form-control-wrap"><input type="Number" className="form-control"
                                                                          id="pf_input" onChange={(e) => setperformancefee(e.target.value)}/></div>
                            </div>
                        </div>



                        {errMsg && (
                            <div className="col-12">
                                <Alert color="danger" className="alert-icon">
                                    {" "}
                                    <Icon name="alert-circle" /> {errMsg}
                                </Alert>
                            </div>
                        )}
                        <div className="col-12">
                            <div className="form-group">
                                <button className="btn btn-lg btn-primary" type="submit">Calculate Return</button>
                            </div>
                        </div>
                    </div>
                </form>
                <ReturnCalculator_Table data={pastcalculations}></ReturnCalculator_Table>
            </div>
        </React.Fragment>
    )
}

export default Form_Return_Calculator