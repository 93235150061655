import React, {useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import Icon from '../../../../assets/components/icon/Icon';
import {AudienceLineChart, BalanceEquityLineChart} from "../balance_equity_chart/BalanceChart";
import {CardTitle} from "reactstrap";
import salesOverview from "./Data";
import axios from "../../../../api/axios";
import {LineChart, BarChart} from "./Charts";
import moment from 'moment';
const ACCOUNT_STATISTIC = '/api/rtm/portfolio_statistic/';
const ACCOUNT_RETURN = '/api/rtm/portfolio_return_table/';
const MONTHLY_RETURN = '/api/rtm/portfolio_return_monthly/'
const WEEKLY_RETURN = '/api/rtm/portfolio_return_weekly/'
const PortfolioStatistik = () => {
    const [cardTitel, setCardTitel] = useState("Statistics");
    const [statistics, setStatistics] = useState({
        "total_winning_trades": 0,
        "account_id": 0,
        "average_win": 0,
        "best_trade": 0,
        "total_longs_winning_trades": 0,
        "total_trade_count": 0,
        "user_id": 0,
        "average_loss": -0,
        "worst_trade": -0,
        "total_shorts_winning_trades": 0,
        "total_short_trades": 0,
        "total_long_trades": 0,
        "average_profit": 0,
        "profit_today": 0,
        "return_today": 0,
        "return_week": 0,
        "return_month": 0,
        "return_total": 0,
        "open_positions": 0,
        "open_lot": 0,
        "open_lot_fx": 0,
        "open_lot_crypto": 0,
        "open_lot_cfd": 0,
        "closed_lot": 0,
        "volume_usd": 0.0
    })
    const [account_return, setAccountReturn] = useState({});
    const [account_return_raw, setAccountReturnRaw] = useState([]);
    const [monthly_return_data, setMonthlyReturn] = useState({})
    const [weekly_return_data, setWeeklyReturn] = useState({})
    const [loaded, setLoaded] = useState(false);
    const params = useParams()
    const [auOverview, setAuOverview] = useState("ITD");

    function formatcurrencyFormat(num) {
        return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '1,')
    }

    function currencyFormat(amount) {
        return (amount).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    }

    useEffect(() => {
        async function fetchAccountStatistic() {
            try {
                var token = "";
                if (typeof window !== 'undefined') {
                    token = localStorage.getItem('Token');
                }
                const response = await axios.get(ACCOUNT_STATISTIC + params.portId, {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Authorization': ['Bearer ', token].join('')
                    }
                });

                setStatistics(await response?.data);

            } catch (e) {
                console.log(e);
            }
        }

        async function fetchAccountReturn() {
            try {
                var token = "";
                if (typeof window !== 'undefined') {
                    token = localStorage.getItem('Token');
                }
                const response = await axios.get(ACCOUNT_RETURN + params.portId, {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Authorization': ['Bearer ', token].join('')
                    }
                });


                setAccountReturnRaw(await response?.data);
                setAccountReturn({
                    labels: await response?.data.map(a => a.date),
                    dataUnit: "BTC",
                    datasets: [
                        {
                            label: "Return Overview",
                            color: "#798bff",
                            fill: true,
                            backgroundColor: "rgba(101,118,255,0.25)",
                            borderColor: "#798bff",
                            barPercentage: 0.1,
                            categoryPercentage: 0.1,
                            borderWidth: 2,
                            lineTension: 0.1,
                            pointBorderColor: "transparent",
                            pointBackgroundColor: "transparent",
                            pointHoverBorderColor: "#798bff",
                            pointHoverBackgroundColor: "#fff",
                            data: await response?.data.map(b => (b.total_return * 100).toFixed(2)),
                        },
                    ],
                });


            } catch (e) {
                console.log(e);
            }
        }

        async function fetchMonthlyReturn() {
            try {
                var token = "";
                if (typeof window !== 'undefined') {
                    token = localStorage.getItem('Token');
                }
                const response = await axios.get(MONTHLY_RETURN + params.portId, {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Authorization': ['Bearer ', token].join('')
                    }
                });


                setMonthlyReturn(
                    {
                        labels: await response?.data.map(a => a.year_month),
                        dataUnit: "USD",
                        stacked: false,
                        datasets: [
                            {
                                label: "Monthly Return",
                                backgroundColor: await response?.data.map((item, i) => {
                                    if (i === response.data.length - 1) {
                                        return "rgba(101,118,255,1)";
                                    } else {
                                        return "rgba(101,118,255,0.2)";
                                    }
                                }),
                                data: await response?.data.map(a => (a.monthly_return * 100).toFixed(2)),
                            },
                        ],
                    }
                );


            } catch (e) {
                console.log(e);
            }
        }

        async function fetchWeeklyReturn() {
            try {
                var token = "";
                if (typeof window !== 'undefined') {
                    token = localStorage.getItem('Token');
                }
                const response = await axios.get(WEEKLY_RETURN + params.portId, {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Authorization': ['Bearer ', token].join('')
                    }
                });


                setWeeklyReturn(
                    {
                        labels: await response?.data.map(a => a.week),
                        dataUnit: "USD",
                        stacked: false,
                        datasets: [
                            {
                                label: "Monthly Return",
                                backgroundColor: await response?.data.map((item, i) => {
                                    if (i === response.data.length - 1) {
                                        return "rgba(101,118,255,1)";
                                    } else {
                                        return "rgba(101,118,255,0.2)";
                                    }
                                }),
                                data: await response?.data.map(a => (a.weekly_return * 100).toFixed(2)),
                            },
                        ],
                    }
                );


            } catch (e) {
                console.log(e);
            }
        }

        fetchAccountStatistic();
        fetchAccountReturn();
        fetchMonthlyReturn();
        fetchWeeklyReturn();
        setLoaded(true);
    }, []);


    useEffect(() => {

        var filtered_date = [];
        if (auOverview == "1M")
        {
            let date = moment(new Date()).subtract(30, 'days').format("YYYY-MM-DD");
            const filtered = account_return_raw.filter(item => {
                return item.date >= date.toString();
            });
            filtered_date = filtered;
        }else if (auOverview == "3M")
        {
            let date = moment(new Date()).subtract(90, 'days').format("YYYY-MM-DD");
            const filtered = account_return_raw.filter(item => {
                return item.date >= date.toString();
            });
            filtered_date = filtered;
        }else if (auOverview == "6M")
        {
            let date = moment(new Date()).subtract(180, 'days').format("YYYY-MM-DD");
            const filtered = account_return_raw.filter(item => {
                return item.date >= date.toString();
            });
            filtered_date = filtered;
        }
        else if (auOverview == "12M")
        {
            let date = moment(new Date()).subtract(365, 'days').format("YYYY-MM-DD");
            const filtered = account_return_raw.filter(item => {
                return item.date >= date.toString();
            });
            filtered_date = filtered;

        }
        else if (auOverview == "ITD")
        {
            filtered_date = account_return_raw;
        }

        setAccountReturn({
            labels: filtered_date.map(a => a.date),
            dataUnit: "BTC",
            datasets: [
                {
                    label: "Return Overview",
                    color: "#798bff",
                    fill: true,
                    backgroundColor: "rgba(101,118,255,0.25)",
                    borderColor: "#798bff",
                    barPercentage: 0.1,
                    categoryPercentage: 0.1,
                    borderWidth: 2,
                    lineTension: 0.1,
                    pointBorderColor: "transparent",
                    pointBackgroundColor: "transparent",
                    pointHoverBorderColor: "#798bff",
                    pointHoverBackgroundColor: "#fff",
                    data: filtered_date.map(b => (b.total_return * 100).toFixed(2)),
                },
            ],
        });


    }, [auOverview])


    if (loaded == true) {
        return (
            <React.Fragment>
                <div className="card-inner">
                    <div className="card-title-group">
                        <div className="card-title card-title-sm">
                            <h6 className="title">{cardTitel}</h6>

                        </div>
                        <div className="card-tools">
                            <ul className="card-tools-nav">
                                <li className={cardTitel === "Return" ? "active" : ""}
                                    onClick={() => setCardTitel("Return")}>
                                    <a
                                        href="#Return"
                                        onClick={(ev) => {
                                            ev.preventDefault();
                                        }}
                                    >
                                        <span>Return</span>
                                    </a>
                                </li>
                                <li className={cardTitel === "Monthly" ? "active" : ""}
                                    onClick={() => setCardTitel("Monthly")}>
                                    <a
                                        href="#Monthly"
                                        onClick={(ev) => {
                                            ev.preventDefault();
                                        }}
                                    >
                                        <span>Monthly</span>
                                    </a>
                                </li>
                                <li className={cardTitel === "Weekly" ? "active" : ""}
                                    onClick={() => setCardTitel("Weekly")}>
                                    <a
                                        href="#Weekly"
                                        onClick={(ev) => {
                                            ev.preventDefault();
                                        }}
                                    >
                                        <span>Weekly</span>
                                    </a>
                                </li>
                                <li className={cardTitel === "Statistics" ? "active" : ""}
                                    onClick={() => setCardTitel("Statistics")}>
                                    <a
                                        href="#Statistics"
                                        onClick={(ev) => {
                                            ev.preventDefault();
                                        }}
                                    >
                                        <span>Statistics</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>


                {cardTitel == "Statistics" ? (
                    <div className="pricing-body">
                        <div className="row">
                            <div className="col">
                                <ul className="pricing-features">
                                    <li><span className="w-50">Total Trades</span> <span
                                        className="ms-auto">{statistics.total_trade_count}</span></li>
                                    <li><span className="w-50">Total Trades won</span> <span
                                        className="ms-auto">{statistics.total_winning_trades}</span></li>
                                    <li><span className="w-50">Total Longs won</span> <span
                                        className="ms-auto">{statistics.total_longs_winning_trades}/{statistics.total_long_trades}</span>
                                    </li>
                                    <li><span className="w-50">Total Shorts won</span> <span
                                        className="ms-auto">{statistics.total_shorts_winning_trades}/{statistics.total_short_trades}</span>
                                    </li>
                                    <li><span className="w-50">Profit Factor</span> <span
                                        className="ms-auto">{(statistics.total_winning_trades / (statistics.total_trade_count - statistics.total_winning_trades)).toFixed(2)}</span>
                                    </li>
                                    {statistics.profit_today < 0 ? (
                                            <li><span className="w-50">Profit Today</span> <span
                                                className="text-danger">{currencyFormat(statistics.profit_today)} </span> EUR
                                            </li>
                                        ) :
                                        (
                                            <li><span className="w-50">Profit Today</span> <span
                                                className="text-success">{currencyFormat(statistics.profit_today)} </span> EUR
                                            </li>
                                        )}


                                </ul>
                            </div>
                            <div className="col">
                                <ul className="pricing-features">
                                    <li><span className="w-50">Best Trade</span> <span
                                        className="text-success">{currencyFormat(statistics.best_trade)} </span> EUR
                                    </li>
                                    <li><span className="w-50">Worst Trade</span> <span
                                        className="text-danger">{currencyFormat(statistics.worst_trade)} </span> EUR
                                    </li>
                                    <li><span className="w-50">Average Win</span> <span
                                        className="text-success">{currencyFormat(statistics.average_profit)} </span> EUR
                                    </li>
                                    <li><span className="w-50">Average Loss</span> <span
                                        className="text-danger">{currencyFormat(statistics.average_loss)} </span> EUR
                                    </li>
                                    {statistics.return_today < 0 ? (
                                            <li><span className="w-50">Return Today</span> <span
                                                className="text-danger">{currencyFormat(statistics.return_today * 100)} </span> %
                                            </li>
                                        ) :
                                        (
                                            <li><span className="w-50">Return Today</span> <span
                                                className="text-success">{currencyFormat(statistics.return_today * 100)} </span> %
                                            </li>
                                        )}
                                    {statistics.return_week < 0 ? (
                                            <li><span className="w-50">Return Week</span> <span
                                                className="text-danger">{currencyFormat(statistics.return_week * 100)} </span> %
                                            </li>
                                        ) :
                                        (
                                            <li><span className="w-50">Return Week</span> <span
                                                className="text-success">{currencyFormat(statistics.return_week * 100)} </span> %
                                            </li>
                                        )}
                                    {statistics.return_month < 0 ? (
                                            <li><span className="w-50">Return Month</span> <span
                                                className="text-danger">{currencyFormat(statistics.return_month * 100)} </span> %
                                            </li>
                                        ) :
                                        (
                                            <li><span className="w-50">Return Month</span> <span
                                                className="text-success">{currencyFormat(statistics.return_month * 100)} </span> %
                                            </li>
                                        )}
                                    {statistics.return_total < 0 ? (
                                            <li><span className="w-50">Return Total</span> <span
                                                className="text-danger">{currencyFormat(statistics.return_total * 100)} </span> %
                                            </li>
                                        ) :
                                        (
                                            <li><span className="w-50">Return Total</span> <span
                                                className="text-success">{currencyFormat(statistics.return_total * 100)} </span> %
                                            </li>
                                        )}
                                    <li><span className="w-50">Open Positions</span> <span
                                        className="ms-auto">{(statistics.open_positions).toFixed(0)}</span></li>
                                    <li><span className="w-50">Open Lot (Total/FX/CRYPTO/CFD)</span> <span
                                        className="ms-auto">{(statistics.open_lot).toFixed(2)}/{(statistics.open_lot_fx).toFixed(2)}/{(statistics.open_lot_crypto).toFixed(2)}/{(statistics.open_lot_cfd).toFixed(2)}</span>
                                    </li>
                                    <li><span className="w-50">Open Volume</span> <span
                                        className="ms-auto">{(currencyFormat(statistics.volume_usd))} USD</span></li>
                                    <li><span className="w-50">Closed Lot</span> <span
                                        className="ms-auto">{(statistics.closed_lot).toFixed(2)}</span></li>
                                </ul>
                            </div>
                        </div>


                    </div>
                ) : cardTitel == "Return" ? (
                    <div className="card-full">
                        <div className="card-title-group pb-3 g-2">
                            <div className="card-title card-title-sm">
                            </div>
                            <div className="card-tools shrink-0 d-none d-sm-block">
                                <ul className="nav nav-switch-s2 nav-tabs bg-white">
                                    <li className="nav-item" id="1M">
                                        <a
                                            href="#navitem"
                                            className={auOverview === "1M" ? "nav-link active" : "nav-link"}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setAuOverview("1M");
                                            }}
                                        >
                                            1 M
                                        </a>
                                    </li>
                                    <li className="nav-item" id="3M">
                                        <a
                                            href="#navitem"
                                            className={auOverview === "3M" ? "nav-link active" : "nav-link"}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setAuOverview("3M");
                                            }}
                                        >
                                            3 M
                                        </a>
                                    </li>
                                    <li className="nav-item" id="6M">
                                        <a
                                            href="#navitem"
                                            className={auOverview === "6M" ? "nav-link active" : "nav-link"}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setAuOverview("6M");
                                            }}
                                        >
                                            6 M
                                        </a>
                                    </li>
                                    <li className="nav-item" id="12M">
                                        <a
                                            href="#navitem"
                                            className={auOverview === "12M" ? "nav-link active" : "nav-link"}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setAuOverview("12M");
                                            }}
                                        >
                                            12 M
                                        </a>
                                    </li>
                                    <li className="nav-item" id="ITD">
                                        <a
                                            href="#navitem"
                                            className={auOverview === "ITD" ? "nav-link active" : "nav-link"}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setAuOverview("ITD");
                                            }}
                                        >
                                            ITD
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="analytic-ov">
                            <div className="analytic-ov-ck">
                                <LineChart data={account_return}/>
                            </div>
                        </div>

                    </div>
                ) : cardTitel == "Monthly" ? (
                    <div className="nk-sales-ck large pt-4"><BarChart chartdata={monthly_return_data}/></div>
                ) : (
                    <div className="nk-sales-ck large pt-4"><BarChart chartdata={weekly_return_data}/></div>
                )}

                <div className="chart-label-group ml-5">
                    <div className="chart-label"></div>
                    <div className="chart-label d-none d-sm-block"></div>
                    <div className="chart-label"></div>
                </div>

            </React.Fragment>
        );
    }
};
export default PortfolioStatistik;