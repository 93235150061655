import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Button } from "../../../../assets/components/Component";
import axios from "../../../../api/axios";
import {
  DataTableBody,
  DataTableHead,
  DataTableItem,
  DataTableRow,
} from "../../../../assets/components/table/DataTable";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import Icon from "../../../../assets/components/icon/Icon";
import moment from "moment";
import TradingSystemsTable from "./TradingSystemsAccountTable";

const SYSTEMACCOUNTS = "/api/tp/system_accounts/";
const SYSTEMMAPPING = "/api/tp/tradingsystem_mapping";

const SystemDetails = () => {
  const params = useParams();
  const [errMsg, setErrMsg] = useState("");
  const [tradingsystems, setTradingsystems] = useState(
    JSON.parse(localStorage.getItem("trading_systems_list"))
  );
  const [traderall, setTraderall] = useState(
    JSON.parse(localStorage.getItem("trader"))
  );
  const [traderselected, setTraderselected] = useState({});
  const [roles, setRoles] = useState(localStorage.getItem("Roles"));
  const [accounts, setAccount] = useState(
    JSON.parse(localStorage.getItem("accounts"))
  );
  const [SystemTradingAccounts, setSystemTradingAccounts] = useState([]);

  const [showAddTradingAccount, setShowAddTradingAccount] = useState(false);
  const [selectedTradingAccount, setSelectedShowTradingAccount] =
    useState(null);
  const [loading, setLoading] = useState(false);

  async function fetchSystemAccounts() {
    try {
      var token = "";
      if (typeof window !== "undefined") {
        token = localStorage.getItem("Token");
      }
      const response = await axios.get(SYSTEMACCOUNTS + params.tradingsystem, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: ["Bearer ", token].join(""),
        },
      });

      setSystemTradingAccounts(await response?.data);
      return;
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    const filtered = traderall.filter((item) => {
      return item.trader_id === tradingsystems[params.tradingsystem].trader_id;
    });
    setTraderselected(filtered[0]);

    fetchSystemAccounts();
  }, []);

  function currencyFormat(amount) {
    return amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
  }

  const handleAddNewTradingAccount = async () => {
    setLoading(true);
    try {
      var token = "";
      if (typeof window !== "undefined") {
        token = localStorage.getItem("Token");
      }

      await axios.post(
        SYSTEMMAPPING,
        {
          system_id: params.tradingsystem,
          trading_account_id: selectedTradingAccount,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: ["Bearer ", token].join(""),
          },
        }
      );

      await fetchSystemAccounts();
      setShowAddTradingAccount(false);
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <React.Fragment>
      <div className="card-title-group pb-3 g-2">
        <div className="card-title card-title-sm">
          <h6 className="title">Trading System</h6>
        </div>
      </div>
      <div className="profile-ud-list">
        <div className="profile-ud-item">
          <div className="profile-ud wider">
            <span className="profile-ud-label">System Name</span>
            <span className="profile-ud-value">
              {tradingsystems[params.tradingsystem].system_name}
            </span>
          </div>
        </div>
        <div className="profile-ud-item">
          <div className="profile-ud wider">
            <span className="profile-ud-label">System Type</span>
            <span className="profile-ud-value">
              {tradingsystems[params.tradingsystem].system_type}
            </span>
          </div>
        </div>
        <div className="profile-ud-item">
          <div className="profile-ud wider">
            <span className="profile-ud-label">System AUM</span>
            <span className="profile-ud-value">
              {currencyFormat(
                tradingsystems[params.tradingsystem].aum_trading_system
              )}
            </span>
          </div>
        </div>
        <div className="profile-ud-item">
          <div className="profile-ud wider">
            <span className="profile-ud-label">System AUM max</span>
            <span className="profile-ud-value">
              {currencyFormat(
                tradingsystems[params.tradingsystem].aum_trading_system_max
              )}
            </span>
          </div>
        </div>
        <div className="profile-ud-item">
          <div className="profile-ud wider">
            <span className="profile-ud-label">Total Lot</span>
            <span className="profile-ud-value">
              {tradingsystems[params.tradingsystem].total_lots.toFixed(2)}
            </span>
          </div>
        </div>
        <div className="profile-ud-item">
          <div className="profile-ud wider">
            <span className="profile-ud-label">System Quality Score</span>
            <span className="profile-ud-value">
              {tradingsystems[
                params.tradingsystem
              ].system_quality_score.toFixed(2)}
            </span>
          </div>
        </div>

        <div className="profile-ud-item">
          <div className="profile-ud wider">
            <span className="profile-ud-label">Profit Factor</span>
            <span className="profile-ud-value">
              {tradingsystems[
                params.tradingsystem
              ].system_profit_factor.toFixed(2)}
            </span>
          </div>
        </div>
        <div className="profile-ud-item">
          <div className="profile-ud wider">
            <span className="profile-ud-label">Monthly avg Return</span>
            <span className="profile-ud-value">
              {(
                tradingsystems[params.tradingsystem].system_avg_monthly_return *
                100
              ).toFixed(2)}{" "}
              %
            </span>
          </div>
        </div>
        <div className="profile-ud-item">
          <div className="profile-ud wider">
            <span className="profile-ud-label">Drawdown avg</span>
            <span className="profile-ud-value">
              {(
                tradingsystems[params.tradingsystem].system_avg_dd * 100
              ).toFixed(2)}{" "}
              %
            </span>
          </div>
        </div>

        <div className="profile-ud-item">
          <div className="profile-ud wider">
            <span className="profile-ud-label">Drawdown max</span>
            <span className="profile-ud-value">
              {(
                tradingsystems[params.tradingsystem].system_max_dd * 100
              ).toFixed(2)}{" "}
              %
            </span>
          </div>
        </div>
      </div>
      <div className="nk-divider divider md"></div>

      <div className="card-title-group pb-3 g-2">
        <div className="card-title card-title-sm">
          <h6 className="title">System Description</h6>
        </div>
      </div>
      <div className="bq-note">
        <div className="bq-note-item" key="{item.id}">
          <div className="bq-note-text">
            <p>{tradingsystems[params.tradingsystem].system_description}</p>
          </div>
        </div>
      </div>
      <div className="nk-divider divider md"></div>

      <div className="card-title-group pb-3 g-2">
        <div className="card-title card-title-sm d-flex justify-content-between align-items-center flex-wrap w-100">
          <h6 className="title">Trading Accounts</h6>
          <button
            className="btn btn-primary"
            onClick={() => setShowAddTradingAccount(true)}
          >
            Add Trading Account
          </button>
        </div>
      </div>
      <Modal centered isOpen={showAddTradingAccount}>
        <ModalHeader toggle={() => setShowAddTradingAccount((prev) => !prev)}>
          Add new trading account
        </ModalHeader>
        <ModalBody>
          <div className="mt-1">
            <UncontrolledDropdown>
              <DropdownToggle caret>
                {accounts[selectedTradingAccount] || "Select trading account"}
              </DropdownToggle>
              <DropdownMenu>
                {Object.keys(accounts)?.map((acc, i) => (
                  <DropdownItem
                    className="text-white"
                    key={i}
                    onClick={() => setSelectedShowTradingAccount(acc)}
                  >
                    {accounts[acc]}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="secondary"
            onClick={() => setShowAddTradingAccount(false)}
          >
            Cancel
          </Button>
          <Button disabled={loading} color="primary" onClick={handleAddNewTradingAccount}>
            {loading ? "Adding..." : "Add"}
          </Button>
        </ModalFooter>
      </Modal>

      <div className="bq-note">
        <TradingSystemsTable data={SystemTradingAccounts} referch={fetchSystemAccounts}></TradingSystemsTable>
      </div>
    </React.Fragment>
  );
};

export default SystemDetails;
