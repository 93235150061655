import React, {useState, Outlet, useEffect} from "react";
import {NavLink, Link, useParams, useNavigate} from "react-router-dom";
import {
    Block,
    BlockHead,
    BlockHeadContent,
    BlockTitle,
    BlockDes,
    BackTo,
    PreviewCard,
    CodeBlock,
    Button,
    PreviewTable,
    Row,
    Col
} from "../../../../assets/components/Component";
import axios from "../../../../api/axios";
import {DataTableBody, DataTableHead, DataTableItem, DataTableRow} from "../../../../assets/components/table/DataTable";
import {Alert, UncontrolledAlert} from "reactstrap";
import Icon from "../../../../assets/components/icon/Icon";
import moment from "moment/moment";


const COMMENT = "/api/tp/system_comments/";
const SystemComments = () => {

    const params = useParams()
    const [errMsg, setErrMsg] = useState("");
    const [commentMsg, setCommentMsg] = useState("");
    const [commentsList, setCommentsList] = useState([]);
    const [userList, setUserList] = useState({1: 'Lars Joeressen', 3: 'Michael Lachner', 5: 'Fabian Fälchle', 6: 'Stefan Burkard'});


    useEffect(() => {

        async function fetchComments() {
            try {
                var token = "";
                if (typeof window !== 'undefined') {
                    token = localStorage.getItem('Token');
                }
                const response = await axios.get(COMMENT + params.tradingsystem, {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Authorization': ['Bearer ', token].join('')
                    }
                });

                setCommentsList(await response?.data);
            } catch (e) {
                console.log(e);
            }
        }


        fetchComments();
    }, []);


    const createComment = async (e) => {
        e.preventDefault();

        if (commentMsg != "") {

            try {
                var token = "";
                if (typeof window !== 'undefined') {
                    token = localStorage.getItem('Token');
                }

                const response = await axios.post(COMMENT, {
                        "system_id": Number(params.tradingsystem),
                        "comment_tx": commentMsg,
                        "comment_ts": Date.now(),
                    },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': ['Bearer ', token].join('')
                        }
                    });

                var data_tmp = commentsList;
                data_tmp.push(await response?.data);
                setCommentMsg(data_tmp);
                document.getElementById('TraderDescription').value = "";
                setCommentMsg("");
                setErrMsg("")
            } catch (err) {
                console.log(err);
                setErrMsg("Comment couldn't be posted... Please try again later")
            }
        } else {
            setErrMsg("please write a comment...")
        }
    }
    return (
        <React.Fragment>
            <div className="card-title-group pb-3 g-2">
                <div className="card-title card-title-sm">
                    <h6 className="title">Comments</h6>
                </div>
            </div>


            <div className="card card-bordered">
                <ul className="nk-support">

                    {commentsList.map((item) => {
                        return (
                            <li className="nk-support-item" key={item.comment_id}>
                                <div className="nk-support-content">
                                    <div className="title"><span>{userList[item.user_id]}</span></div>
                                    <p>{item.comment_tx} </p>
                                    <span className="time">{moment.utc(item.comment_ts).fromNow(true)} ago</span>
                                </div>
                            </li>
                        );
                    })}



                </ul>

                <div className="card-inner border-bottom">

                    <Row>
                            <textarea className="form-control no-resize" name="message" id="TraderDescription"
                                      rows={1}
                                      cols={50}
                                      placeholder="Type Comment.."
                                      onChange={(e) => {
                                          setCommentMsg(e.target.value);
                                          setErrMsg("")
                                      }}/>
                        <button className="btn btn-primary" onClick={createComment}>Add Comment</button>

                    </Row>
                    {errMsg && (
                        <Alert color="danger" className="alert-icon">
                            {" "}
                            <Icon name="alert-circle"/> {errMsg}
                        </Alert>
                    )}


                </div>
            </div>

        </React.Fragment>
    )

};

export default SystemComments;