import React, {useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import Icon from '../../../../assets/components/icon/Icon';
import {AudienceLineChart} from "../balance_equity_chart/BalanceChart";
import {CardTitle} from "reactstrap";
import axios from "../../../../api/axios";
import {Doughnut, Bar} from "react-chartjs-2";

import {
    PreviewAltCard,
} from "../../../../assets/components/Component";

import io from 'socket.io-client';

function Killswitch() {
    const [trading_account, setTradingAccount] = useState("44569217")
    const [isconnected, setisconnected] = useState(false);
    const [socketdata, setsocketdata] = useState({
        "RealtimeStats": "AccountNo",
        "Time": "not connected",
        "Balance": "0",
        "Equity": "0",
        "NoOfOpenTrades": "0",
        "NoOfOpenLots": "0",
        "NoOfOpenOrders": "0",
        "NoOfOpenOrdersLots": "0.00",
        "NoOfClosedTrades": "0",
        "UsedMargin": "0",
        "FreeMargin": "0",
        "MarginLevel": "0",
        "Deposit": "0.00",
        "NoOfOpenCharts": "1!"
    });
    const [response, setResponse] = useState("");

    useEffect(() => {
        const socket = io("ws://127.0.0.1:8080");
        socket.on(trading_account, data => {
            setsocketdata(data);
            console.log(data);
        });
        socket.on("connect", function() {
            setisconnected(true);
        });
    }, []);

    return (
        <React.Fragment>


            <div className="card-title-group align-start pb-3 g-2">
                <div className="card-title card-title-sm">
                    <h6 className="title">
                    {isconnected == true ? (
                            <div className="status dot dot-lg dot-success"></div>
                    ):
                        (
                            <div className="status dot dot-lg dot-danger"></div>
                        )}
                    <span> Kill Switch</span></h6>

                    </div>
                <button>Equity Threshold</button>
                <button>Drawdown Threshold</button>
            </div>
            <div className="card-inner">
                <p>Last update: {socketdata.Time}</p>
                <p>Balance: {socketdata.Balance}</p>
                <p>Equity: {socketdata.Equity}</p>
                <p>used Margin: {socketdata.UsedMargin}</p>
                <p>Free Margin: {socketdata.FreeMargin}</p>
                <p>Margin Level: {socketdata.MarginLevel}</p>
                <p>Open Lots: {socketdata.NoOfOpenLots}</p>
                <p>Open Orders: {socketdata.NoOfOpenOrders}</p>

            </div>
        </React.Fragment>
    );
}

export default Killswitch;

