import React, {useState, Outlet, useEffect} from "react";
import {NavLink, Link, useParams, useNavigate} from "react-router-dom";
import {
    Block,
    BlockHead,
    BlockHeadContent,
    BlockTitle,
    BlockDes,
    BackTo,
    PreviewCard,
    CodeBlock,
    Button,
    PreviewTable,
} from "../../../../assets/components/Component";
import axios from "../../../../api/axios";
import {DataTableBody, DataTableHead, DataTableItem, DataTableRow} from "../../../../assets/components/table/DataTable";
import { Alert, UncontrolledAlert } from "reactstrap";
import Icon from "../../../../assets/components/icon/Icon";
import moment from 'moment';


const TraderDetails = () => {

    const params = useParams()
    const [errMsg, setErrMsg] = useState("");
    const [tradingsystems, setTradingsystems] = useState(JSON.parse(localStorage.getItem("trading_systems_list")));
    const [traderall, setTraderall] = useState(JSON.parse(localStorage.getItem("trader")));
    const [traderselected, setTraderselected] = useState({});
    const [roles, setRoles] = useState(localStorage.getItem('Roles'));

    useEffect(() => {
        const filtered = traderall.filter(item => {
            return item.trader_id === tradingsystems[params.tradingsystem].trader_id;
        });
        setTraderselected(filtered[0]);
    }, []);

    function currencyFormat(amount){
        return (amount).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    }

    return (
        <React.Fragment>
            <div className="card-title-group pb-3 g-2">
                <div className="card-title card-title-sm">
                    <h6 className="title">Trader Profile</h6>
                </div>
            </div>
            <div className="profile-ud-list">
                <div className="profile-ud-item">
                    <div className="profile-ud wider">
                        <span className="profile-ud-label">Trader Name</span>
                        <span className="profile-ud-value">{traderselected.trader_name}</span>
                    </div>
                </div>
                <div className="profile-ud-item">
                    <div className="profile-ud wider">
                        <span className="profile-ud-label">Email</span>
                        <span className="profile-ud-value">{traderselected.trader_email}</span>
                    </div>
                </div>
                <div className="profile-ud-item">
                    <div className="profile-ud wider">
                        <span className="profile-ud-label">Registered at</span>
                        <span className="profile-ud-value">{traderselected.registered_at}</span>
                    </div>
                </div>
                <div className="profile-ud-item">
                    <div className="profile-ud wider">
                        <span className="profile-ud-label">Active</span>
                        {tradingsystems[params.tradingsystem].aum_trader > 0 ?(
                            <span className="profile-ud-value">YES</span>
                        ):(<span className="profile-ud-value">NO</span>)}
                    </div>
                </div>
                <div className="profile-ud-item">
                    <div className="profile-ud wider">
                        <span className="profile-ud-label">Active Months</span>
                        <span className="profile-ud-value">{moment(new Date()).diff(moment(new Date(tradingsystems[params.tradingsystem].earliest_acitve)), 'months')}</span>
                    </div>
                </div>
                <div className="profile-ud-item">
                    <div className="profile-ud wider">
                        <span className="profile-ud-label">AUM</span>
                        <span className="profile-ud-value">{currencyFormat(tradingsystems[params.tradingsystem].aum_trader)}</span>
                    </div>
                </div>
                <div className="profile-ud-item">
                    <div className="profile-ud wider">
                        <span className="profile-ud-label">Product Categories</span>
                        <span className="profile-ud-value">{tradingsystems[params.tradingsystem].product_category}</span>
                    </div>
                </div>
            </div>
            <div className="nk-divider divider md"></div>
            <div className="card-title-group pb-3 g-2">
                <div className="card-title card-title-sm">
                    <h6 className="title">Trader Description</h6>
                </div>
            </div>
            <div className="bq-note">

                <div className="bq-note-item" key="{item.id}">
                    <div className="bq-note-text">
                        <p>{traderselected.trader_description}</p>
                    </div>
                </div>
            </div>

        </React.Fragment>
    )

};

export default TraderDetails;