import React, { useState, useEffect } from "react";
import Head from "../../components/head/Head";
import Content from "../../components/content/Content";

import {
  Card,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Progress,
  Alert,
} from "reactstrap";
import {
  Block,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockBetween,
  BlockTitle,
  Icon,
  Button,
  Row,
  Col,
} from "../../assets/components/Component";

import Form_newTrader from "./Components/CreateTraderSystem/FormCreateTrader";
import { Context } from "./Context.js";
import Form_newSystem from "./Components/CreateTraderSystem/FormCreateSystem";
import axios from "../../api/axios";
import TradingSystemsList from "./Components/TradingSystemsMainList/TradingSystemsList";

const TRADER = "/api/tp/trader";
const ACCOUNTS_URL = "/api/rtm/allowed_accounts";
const SYSTEMMAPPING = "/api/tp/tradingsystem_mapping";
const TRADINGSYSTEMS = "/api/tp/system_list";

const TraderPerformance = () => {
  const [sm, updateSm] = useState(false);
  const [showCreateTrader, setShowCreateTrader] = useState(false);
  const [showCreateSystem, setShowCreateSystem] = useState(false);
  const [showWizard, setShowWizard] = useState(null);
  const [preSelectedTrader, setpreSelectedTrader] = useState(0);
  const [currentStep, setCurrentStep] = useState(1);
  const [systemslist, setsystemslist] = useState([]);
  const [saving, setSaving] = useState(false);

  async function fetchAccountList() {
    try {
      var token = "";
      if (typeof window !== "undefined") {
        token = localStorage.getItem("Token");
      }
      const response = await axios.get(ACCOUNTS_URL, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: ["Bearer ", token].join(""),
        },
      });
      localStorage.setItem(
          "tradingaccounts_list",
          JSON.stringify(await response?.data)
      );
      setTradingAccountList(await response?.data);
      console.log(TradingAccountList);
    } catch (e) {
      console.log(e);
    }
  }

  async function fetchTraderList() {
    try {
      var token = "";
      if (typeof window !== "undefined") {
        token = localStorage.getItem("Token");
      }
      const response = await axios.get(TRADER, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: ["Bearer ", token].join(""),
        },
      });

      localStorage.setItem("trader", JSON.stringify(await response?.data));
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {


  }, []);
  async function fetchSystems() {
    try {
      var token = "";
      if (typeof window !== "undefined") {
        token = localStorage.getItem("Token");
      }
      const response = await axios.get(TRADINGSYSTEMS, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: ["Bearer ", token].join(""),
        },
      });

      setsystemslist(await response?.data);

      var symstemsl = {};

      for (var i = 0; i < (await response?.data.length); i++) {
        symstemsl[response?.data[i]["system_id"]] = response?.data[i];
      }
      localStorage.setItem("trading_systems_list", JSON.stringify(symstemsl));

    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    fetchSystems();
    fetchTraderList();
    fetchAccountList();
  }, []);

  useEffect(() => {
    setCurrentStep(showWizard);
  }, [showWizard]);

  const [Tradingaccountsselected, setTradingaccountsselected] = useState([]);
  const [SystemID, setSystemID] = useState(null);
  const [errMsg, setErrMsg] = useState("");
  const [TradingAccountList, setTradingAccountList] = useState([]);

  const handleChange = (e) => {
    var selected = [];
    for (var option of document.getElementById("selecttradingaccounts")
      .options) {
      if (option.selected) {
        selected.push(parseInt(option.value));
      }
    }
    setTradingaccountsselected(selected);
  };

  const handleTradingAccountSelectionSave = async (e) => {
    setSaving(true);

    var token = "";
    if (typeof window !== "undefined") {
      token = localStorage.getItem("Token");
    }
    Tradingaccountsselected.forEach(async (account) => {
      console.log(account);
      try {
        const response = await axios.post(
          SYSTEMMAPPING,
          {
            system_id: SystemID,
            trading_account_id: account,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: ["Bearer ", token].join(""),
            },
          }
        );
        console.log(response);
      } catch (err) {
        console.log(err);
        setErrMsg("Something went wrong! Please Try again later.");
      }
    });

    await fetchSystems();
    setShowWizard(null);
    setSaving(false);
  };

  const wizardSteps = [
    {
      id: 1,
      element: (
        <Card className="card-bordered card-full">
          <Context.Provider
            value={[
              showCreateTrader,
              setShowCreateTrader,
              1,
              setCurrentStep,
              setpreSelectedTrader,
            ]}
          >
            <Form_newTrader />
          </Context.Provider>
        </Card>
      ),
    },
    {
      id: 2,
      element: (
        <Card className="card-bordered card-full">
          <Context.Provider
            value={[
              showCreateSystem,
              setShowCreateSystem,
              setSystemID,
              2,
              setCurrentStep,
              preSelectedTrader,
            ]}
          >
            <Form_newSystem />
          </Context.Provider>
        </Card>
      ),
    },
    {
      id: 3,
      element: (
        <>
          <div className="row gy-4">
            <div className="col-lg-12">
              <div className="form-group">
                <label className="form-label" htmlFor="phone-no-1">
                  Trading Accounts
                </label>
                <div className="form-control-wrap">
                  <div className="form-control-wrap">
                    <div className="form-control-select-multiple">
                      <select
                        className="custom-select"
                        type="select"
                        name="select"
                        id="selecttradingaccounts"
                        multiple
                        onChange={(e) => handleChange(e.target.value)}
                      >
                        {TradingAccountList.map((key) => {
                          return (
                            <option key={key.account_id} value={key.account_id}>
                              {key.account_id} - {key.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row gy-4">
            <div className="col-lg-12">
              <div className="group">
                <label className="form-label" htmlFor="phone-no-1"></label>
                <Button
                  disabled={saving}
                  color='primary'
                  onClick={(e) =>
                    handleTradingAccountSelectionSave(e.target.value)
                  }
                >
                  {saving ? "Saving Selection" : "Save Selection"}
                </Button>
              </div>
            </div>
          </div>
          {errMsg && (
            <div className="form-group">
              <div className="form-control-wrap">
                <Alert color="danger" className="alert-icon">
                  {" "}
                  <Icon name="alert-circle" /> {errMsg}
                </Alert>
              </div>
            </div>
          )}
        </>
      ),
    },
  ];



  return (
    <React.Fragment>
      <Head title="Trader Performance" />
      <Content>
        <Modal
          centered
          scrollable
          isOpen={showWizard ? true : false}
          toggle={() => setShowWizard(null)}
        >
          <ModalHeader toggle={() => setShowWizard(null)}>
            Step Wizard
          </ModalHeader>
          <ModalBody>
            <div>
              <div className="text-center mb-1 text-white">
                {currentStep}/{wizardSteps.length}
              </div>
              <Progress value={currentStep} max={wizardSteps.length} />
            </div>

            {wizardSteps.map((ws) => {
              if (ws.id === currentStep) {
                return (
                  <React.Fragment key={ws.id}>{ws.element}</React.Fragment>
                );
              }
            })}
          </ModalBody>
          <ModalFooter>
            <Button color="danger" onClick={() => setShowWizard(false)}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle page>Trader Performance Management</BlockTitle>
              <BlockDes className="text-soft">
                <p>
                  Here you find a List of all Traders and their Trading-Systems.{" "}
                </p>
              </BlockDes>
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <Button
                  className={`btn-icon btn-trigger toggle-expand mr-n1 ${
                    sm ? "active" : ""
                  }`}
                  onClick={() => updateSm(!sm)}
                >
                  <Icon name="more-v" />
                </Button>
                <div
                  className="toggle-expand-content"
                  style={{ display: sm ? "block" : "none" }}
                >
                  <ul className="nk-block-tools g-3">
                    <li className="nk-block-tools-opt">
                      <UncontrolledDropdown>
                        <DropdownToggle
                          color="transparent"
                          className="btn btn-primary btn-icon dropdown-toggle"
                        >
                          <Icon name="plus"></Icon>
                        </DropdownToggle>
                        <DropdownMenu right>
                          <ul className="link-list-opt no-bdr">
                            <li id="addTrader">
                              <DropdownItem
                                tag="a"
                                onClick={() => setShowWizard(1)}
                              >
                                <Icon name="user-add-fill"></Icon>
                                <span>Add Trader</span>
                              </DropdownItem>
                            </li>
                            <li id="addSystem">
                              <DropdownItem
                                tag="a"
                                onClick={() => setShowWizard(2)}
                              >
                                <Icon name="invest"></Icon>
                                <span>Add Trading-System</span>
                              </DropdownItem>
                            </li>
                          </ul>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </li>
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block>
          {showCreateTrader && (
            <Row className="g-gs">
              <Col lg="12">
                <Card className="card-bordered card-full">
                  <Context.Provider
                    value={[showCreateTrader, setShowCreateTrader]}
                  >
                    <Form_newTrader />
                  </Context.Provider>
                </Card>
              </Col>
            </Row>
          )}
          {showCreateSystem && (
            <Row className="g-gs">
              <Col lg="12">
                <Card className="card-bordered card-full">
                  <Context.Provider
                    value={[showCreateSystem, setShowCreateSystem]}
                  >
                    <Form_newSystem />
                  </Context.Provider>
                </Card>
              </Col>
            </Row>
          )}

          <Row className="g-gs">
            <Col lg="12">
              <Card className="card-bordered card-full">
                <TradingSystemsList systemslist={systemslist} />
              </Card>
            </Col>
          </Row>
          <Row className="g-gs">
            <Col lg="12">
              <Card className="card-bordered card-full"></Card>
            </Col>
          </Row>
        </Block>
      </Content>
    </React.Fragment>
  );
};

export default TraderPerformance;
