import Dropzone from "react-dropzone";
import React, { useState, Outlet, useEffect } from "react";
import { NavLink, Link, useParams, useNavigate } from "react-router-dom";
import { Button } from "../../../../assets/components/Component";
import axios from "../../../../api/axios";
import { useDropzone } from "react-dropzone";
import loading from "./loading-gif.gif";
import {
  DataTableBody,
  DataTableHead,
  DataTableItem,
  DataTableRow,
} from "../../../../assets/components/table/DataTable";
import { Alert, Table } from "reactstrap";
import Icon from "../../../../assets/components/icon/Icon";

const FileUploadcomponent = () => {
  const params = useParams();
  const [files, setFiles] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([
    {
      file_id: 1,
      file_link: "/",
      file_name: "6200",
    },
  ]);
  const [errMsg, setErrMsg] = useState("");

  useEffect(() => {
    async function fetchUploadedFiles() {
      try {
        var token = "";
        if (typeof window !== "undefined") {
          token = localStorage.getItem("Token");
        }
        const response = await axios.get(
          "/api/tp/" + params.tradingsystem + "/system_files",
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              Authorization: ["Bearer ", token].join(""),
            },
          }
        );

        setUploadedFiles(await response?.data);
      } catch (e) {
        console.log(e);
      }
    }
    fetchUploadedFiles();
  }, []);

  const handleDropChange = async (acceptedFiles, set) => {
    set(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    );

    try {
      var token = "";
      if (typeof window !== "undefined") {
        token = localStorage.getItem("Token");
      }
      const formData = new FormData();
      formData.append("file", acceptedFiles[0]);
      const response = await axios.post(
        "/api/tp/" + params.tradingsystem + "/upload_system_files",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: ["Bearer ", token].join(""),
          },
        }
      );
      if ((await response.status) == 201) {
        var data_tmp = uploadedFiles;
        data_tmp.push(await response?.data);

        setUploadedFiles(data_tmp);
        setFiles([]);
      }
    } catch (e) {
      console.log(e);
      setErrMsg("Something went wrong. Please try again later!");
    }
  };

  return (
    <React.Fragment>
      <div className="card-title-group pb-3 g-2">
        <div className="card-title card-title-sm">
          <h6 className="title">Files</h6>
        </div>
      </div>
      <Dropzone
        onDrop={(acceptedFiles) => handleDropChange(acceptedFiles, setFiles)}
        maxFiles={1}
      >
        {({ getRootProps, getInputProps }) => (
          <section>
            <div
              {...getRootProps()}
              className="dropzone upload-zone dz-clickable"
            >
              <input {...getInputProps()} />
              {files.length === 0 && (
                <div className="dz-message">
                  <span className="dz-message-text">Drag and drop file</span>
                  <span className="dz-message-or">or</span>
                  <Button color="primary">SELECT</Button>
                </div>
              )}
              {files.length > 0 && (
                <div className="dz-message" id="loadingdiv" key="loadingdiv">
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden"></span>
                  </div>
                </div>
              )}
              {errMsg && (
                <div className="dz-message">
                  <Alert className="alert-icon" color="danger">
                    <Icon name="cross-circle" />
                    <strong>Upload failed</strong>
                    {errMsg}
                  </Alert>
                </div>
              )}
            </div>
          </section>
        )}
      </Dropzone>
      <div className="table-responsive mt-4">
        <Table>
          <thead>
            <tr>
              <th>File</th>
              <th>Download</th>
            </tr>
          </thead>
          <tbody>
            {uploadedFiles.map((item) => (
              <tr key={item.file_id}>
                <td>
                <span className="tb-sub">
                  <span>{item.file_name}</span>
                </span>
                </td>
                <td>
                  <span className="tb-sub tb-amount">
                    <span>
                      <a href={item.file_link}>Download</a>
                    </span>
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </React.Fragment>
  );
};

export default FileUploadcomponent;
