import React, { useState, Outlet, useEffect } from "react";
import { NavLink, Link, useParams, useNavigate } from "react-router-dom";
import {
  Block,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  BlockDes,
  BackTo,
  PreviewCard,
  CodeBlock,
  Button,
  PreviewTable,
  Row,
  Col,
  PreviewAltCard,
} from "../../../../assets/components/Component";
import axios from "../../../../api/axios";
import {
  DataTableBody,
  DataTableHead,
  DataTableItem,
  DataTableRow,
} from "../../../../assets/components/table/DataTable";
import { Alert, UncontrolledAlert } from "reactstrap";
import Icon from "../../../../assets/components/icon/Icon";
import moment from "moment";
import { Badge, Card } from "reactstrap";

import Nouislider from "nouislider-react";
import RadarChartTrader from "../KnowYourTraderChart/RadarChartTrader";

const SYSTEMSCORE = "/api/tp/system_score/";
const KnowYourTrader = () => {
  const params = useParams();
  const [tradingsystems, setTradingsystems] = useState(
    JSON.parse(localStorage.getItem("trading_systems_list"))
  );
  const [traderall, setTraderall] = useState(
    JSON.parse(localStorage.getItem("trader"))
  );
  const [traderselected, setTraderselected] = useState({});
  const [errMsg, setErrMsg] = useState("");
  const [systemScores, setSystemScores] = useState({
    system_id: 1,
    com_acces: 1,
    com_stress: 1,
    com_trust: 1,
    com_quality: 1,
    it_device: 1,
    it_licenses: 1,
    reg_license: 1,
    reg_fulltime: 0,
  });

  const [GlobScore, setGlobScore] = useState(0);
  const [ComScore, setComScore] = useState(0);
  const [ItScore, setItScore] = useState({});
  const [RegScore, setRegScore] = useState({});
  const [RuleViolationScore, setRuleViolationScore] = useState(0);
  const [RadarData, setRadarData] = useState([0, 0, 0, 0, 0]);

  useEffect(() => {
    setComScore(
        (systemScores?.com_acces * 2) +
        (systemScores?.com_stress * 4) +
        (systemScores?.com_quality * 2) +
        systemScores?.com_trust
    );
    setGlobScore(
      productCategoryPoints(
        tradingsystems[params.tradingsystem].product_category
      ) +
        tradingsystems[params.tradingsystem].aum_trader /
          tradingsystems[params.tradingsystem].aum_trading_system_max
    );
    setItScore((systemScores?.it_device + systemScores?.it_licenses) / 4);
    setRegScore((systemScores?.reg_fulltime * systemScores?.reg_license) / 3);
    setRuleViolationScore(
      1 -
        (tradingsystems[params.tradingsystem].total_rule_violations /
          tradingsystems[params.tradingsystem].active_month_rule_violations) *
          (tradingsystems[params.tradingsystem].low_rule_violations /
            tradingsystems[params.tradingsystem].total_rule_violations)
    );
  }, [systemScores]);

  useEffect(() => {
    let radar_data = [
      RuleViolationScore < 0
        ? 0
        : RuleViolationScore > 100
        ? 100
        : Math.round(RuleViolationScore),
      Math.round(tradingsystems[params.tradingsystem].system_quality_score),
      ComScore,
      ItScore * 100,
      RegScore * 100,
      Math.round(
        tradingsystems[params.tradingsystem].system_quality_score *
          (1 - Math.abs(tradingsystems[params.tradingsystem].system_max_dd))
      ),
    ].map((rd) => Number(rd).toFixed(2));

    setRadarData(radar_data);
  }, [RuleViolationScore, tradingsystems, ComScore, ItScore, RegScore]);

  console.log(RegScore);

  useEffect(() => {
    const filtered = traderall.filter((item) => {
      return item.trader_id === tradingsystems[params.tradingsystem].trader_id;
    });
    setTraderselected(filtered[0]);
    async function fetchSystemScores() {
      try {
        var token = "";
        if (typeof window !== "undefined") {
          token = localStorage.getItem("Token");
        }
        const response = await axios.get(SYSTEMSCORE + params.tradingsystem, {
          headers: {
            "Content-Type": "application/json",
            Authorization: ["Bearer ", token].join(""),
          },
        });

        setSystemScores(response.data);
      } catch (e) {
        console.log(e);
      }
    }

    fetchSystemScores();
  }, []);

  function currencyFormat(amount) {
    return amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
  }

  function productCategoryPoints(productcategories) {
    if (productcategories.includes("Insti")) {
      return 2;
    } else if (productcategories.includes("HNWI")) {
      return 1;
    }
  }

  const onChangeSlide = async (data, element) => {
    setSystemScores((prev) => ({ ...prev, [element]: parseInt(data[0]) }));
  };

  useEffect(() => {
    const putSystemScorers = async () => {
      try {
        var token = "";
        if (typeof window !== "undefined") {
          token = localStorage.getItem("Token");
        }
        await axios.put(
          SYSTEMSCORE + params.tradingsystem,
          {
            system_id: params.tradingsystem,
            ...systemScores,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: ["Bearer ", token].join(""),
            },
          }
        );
      } catch (e) {
        console.log(e);
      }
    };

    putSystemScorers();
    console.log("update System Scores")
  }, [systemScores]);

  return (
    <React.Fragment>
      <Row className="g-gs">
        <Col md="7" xxl="7">
          <PreviewAltCard className="card-full">
            <div className="card-title-group pb-3 g-2">
              <div className="card-title card-title-sm">
                <h6 className="title">Global Score</h6>
              </div>
            </div>
            <Card className="card-bordered">
              <ul className="data-list is-compact">
                <li className="data-item">
                  <div className="data-col">
                    <div className="data-label">Product Category</div>
                    <div className="data-value">
                      {tradingsystems[params.tradingsystem].product_category} -{" "}
                      {productCategoryPoints(
                        tradingsystems[params.tradingsystem].product_category
                      )}
                    </div>
                  </div>
                </li>
                <li className="data-item">
                  <div className="data-col">
                    <div className="data-label">AUM Actual</div>
                    <div className="data-value">
                      {currencyFormat(
                        tradingsystems[params.tradingsystem].aum_trader
                      )}
                    </div>
                  </div>
                </li>
                <li className="data-item">
                  <div className="data-col">
                    <div className="data-label">AUM Max</div>
                    <div className="data-value">
                      {currencyFormat(
                        tradingsystems[params.tradingsystem]
                          .aum_trading_system_max
                      )}
                    </div>
                  </div>
                </li>
                <li className="data-item">
                  <div className="data-col">
                    <div className="data-label">Score</div>
                    <div className="data-value text-soft">
                      <em>{GlobScore.toFixed(2)}</em>
                    </div>
                  </div>
                </li>
              </ul>
            </Card>
            <div className="nk-divider divider md"></div>

            <div className="card-title-group pb-3 g-2">
              <div className="card-title card-title-sm">
                <h6 className="title">Trading System Score</h6>
              </div>
            </div>
            <Card className="card-bordered">
              <ul className="data-list is-compact">
                <li className="data-item">
                  <div className="data-col">
                    <div className="data-label">Trading System Quality</div>
                    <div className="data-value">
                      {tradingsystems[
                        params.tradingsystem
                      ].system_quality_score.toFixed(2)}
                    </div>
                  </div>
                </li>
                <li className="data-item">
                  <div className="data-col">
                    <div className="data-label">DD Max</div>
                    <div className="data-value">
                      {(
                        tradingsystems[params.tradingsystem].system_max_dd * 100
                      ).toFixed(2)}{" "}
                      %
                    </div>
                  </div>
                </li>
                <li className="data-item">
                  <div className="data-col">
                    <div className="data-label">Score</div>
                    <div className="data-value text-soft">
                      <em>
                        <span>
                          {(
                            tradingsystems[
                              params.tradingsystem
                            ].system_quality_score.toFixed(2) *
                            (1 -
                              Math.abs(
                                tradingsystems[params.tradingsystem]
                                  .system_max_dd
                              ))
                          ).toFixed(2)}
                        </span>
                      </em>
                    </div>
                  </div>
                </li>
              </ul>
            </Card>
            <div className="nk-divider divider md"></div>

            <div className="card-title-group pb-3 g-2">
              <div className="card-title card-title-sm">
                <h6 className="title">Rule Violation-Score</h6>
              </div>
            </div>
            <Card className="card-bordered">
              <ul className="data-list is-compact">
                <li className="data-item">
                  <div className="data-col">
                    <div className="data-label">Total Violations</div>
                    <div className="data-value">
                      {
                        tradingsystems[params.tradingsystem]
                          .total_rule_violations
                      }
                    </div>
                  </div>
                </li>
                <li className="data-item">
                  <div className="data-col">
                    <div className="data-label">Violations active Months</div>
                    <div className="data-value">
                      {
                        tradingsystems[params.tradingsystem]
                          .active_month_rule_violations
                      }
                    </div>
                  </div>
                </li>
                <li className="data-item">
                  <div className="data-col">
                    <div className="data-label">Low Violations</div>
                    <div className="data-value">
                      {tradingsystems[params.tradingsystem].low_rule_violations}
                    </div>
                  </div>
                </li>
                <li className="data-item">
                  <div className="data-col">
                    <div className="data-label">Score</div>
                    <div className="data-value text-soft">
                      <em>{RuleViolationScore.toFixed(2)}</em>
                    </div>
                  </div>
                </li>
              </ul>
            </Card>
            <div className="nk-divider divider md"></div>

            <div className="card-title-group pb-3 g-2">
              <div className="card-title card-title-sm">
                <h6 className="title">Communication-Score - {ComScore}</h6>
              </div>
            </div>
            <Card className="card-bordered">
              <Row className="gy-4">
                <Col sm="6">
                  <div className="form-group">
                    <label className="form-label">
                      Accessibility / Reactivity
                    </label>
                    <div className="form-control-wrap">
                      <Nouislider
                        className="form-range-slider"
                        accessibility
                        connect={[true, false]}
                        start={systemScores?.com_acces}
                        step={1}
                        id="com_acces"
                        range={{
                          min: 1,
                          max: 3,
                        }}
                        onEnd={(data) => onChangeSlide(data, "com_acces")}
                      ></Nouislider>
                    </div>
                  </div>
                </Col>
                <Col sm="6">
                  <div className="form-group">
                    <label className="form-label">
                      Under stressful situations
                    </label>
                    <div className="form-control-wrap">
                      <Nouislider
                        className="form-range-slider"
                        accessibility
                        connect={[true, false]}
                        start={systemScores?.com_stress}
                        step={1}
                        id="com_stress"
                        range={{
                          min: 1,
                          max: 3,
                        }}
                        onEnd={(data) => onChangeSlide(data, "com_stress")}
                      ></Nouislider>
                    </div>
                  </div>
                </Col>
                <Col sm="6">
                  <div className="form-group">
                    <label className="form-label">
                      Quality of communication
                    </label>
                    <div className="form-control-wrap">
                      <Nouislider
                        className="form-range-slider"
                        accessibility
                        connect={[true, false]}
                        start={systemScores?.com_quality}
                        step={1}
                        range={{
                          min: 1,
                          max: 3,
                        }}
                        id="com_quality"
                        onEnd={(data) => onChangeSlide(data, "com_quality")}
                      ></Nouislider>
                    </div>
                  </div>
                </Col>
                <Col sm="6">
                  <div className="form-group">
                    <label className="form-label">Trust/Honesty-Score</label>
                    <div className="form-control-wrap">
                      <Nouislider
                        className="form-range-slider"
                        accessibility
                        connect={[true, false]}
                        start={systemScores?.com_trust}
                        step={1}
                        range={{
                          min: 1,
                          max: 10,
                        }}
                        id="com_trust"
                        onEnd={(data) => onChangeSlide(data, "com_trust")}
                      ></Nouislider>
                    </div>
                  </div>
                </Col>
              </Row>
            </Card>

            <div className="nk-divider divider md"></div>

            <div className="card-title-group pb-3 g-2">
              <div className="card-title card-title-sm">
                <h6 className="title">
                  IT-Score - {(ItScore * 100).toFixed(2)}
                </h6>
              </div>
            </div>
            <Card className="card-bordered">
              <Row className="gy-4">
                <Col sm="6">
                  <div className="form-group">
                    <label className="form-label">Execution Device</label>
                    <div className="form-control-wrap">
                      <Nouislider
                        className="form-range-slider"
                        accessibility
                        connect={[true, false]}
                        start={systemScores?.it_device}
                        step={1}
                        range={{
                          min: 1,
                          max: 2,
                        }}
                        id="it_device"
                        onEnd={(data) => onChangeSlide(data, "it_device")}
                      ></Nouislider>
                    </div>
                  </div>
                </Col>
                <Col sm="6">
                  <div className="form-group">
                    <label className="form-label">
                      Own purchased licenses of trading technology software{" "}
                    </label>
                    <div className="form-control-wrap">
                      <Nouislider
                        className="form-range-slider"
                        accessibility
                        connect={[true, false]}
                        start={systemScores?.it_licenses}
                        step={1}
                        range={{
                          min: 1,
                          max: 2,
                        }}
                        id="it_licenses"
                        onEnd={(data) => onChangeSlide(data, "it_licenses")}
                      ></Nouislider>
                    </div>
                  </div>
                </Col>
              </Row>
            </Card>

            <div className="nk-divider divider md"></div>

            <div className="card-title-group pb-3 g-2">
              <div className="card-title card-title-sm">
                <h6 className="title">
                  Regulatory-Score - {(RegScore * 100).toFixed(2)}
                </h6>
              </div>
            </div>
            <Card className="card-bordered">
              <Row className="gy-4">
                <Col sm="6">
                  <div className="form-group">
                    <label className="form-label">
                      Which license do you have: N/A, Third Party, by myself
                    </label>
                    <div className="form-control-wrap">
                      <Nouislider
                        className="form-range-slider"
                        accessibility
                        connect={[true, false]}
                        start={systemScores?.reg_license}
                        step={1}
                        range={{
                          min: 1,
                          max: 3,
                        }}
                        id="reg_license"
                        onEnd={(data) => onChangeSlide(data, "reg_license")}
                      ></Nouislider>
                    </div>
                  </div>
                </Col>
                <Col sm="6">
                  <div className="form-group">
                    <label className="form-label">
                      Part-time or Full-time Trader
                    </label>
                    <div className="form-control-wrap">
                      <Nouislider
                        className="form-range-slider"
                        accessibility
                        connect={[true, false]}
                        start={systemScores?.reg_fulltime}
                        step={1}
                        range={{
                          min: 0,
                          max: 1,
                        }}
                        id="reg_fulltime"
                        onEnd={(data) => onChangeSlide(data, "reg_fulltime")}
                      ></Nouislider>
                    </div>
                  </div>
                </Col>
              </Row>
            </Card>
          </PreviewAltCard>
        </Col>
        <Col md="5" xll="5">
          <PreviewAltCard className="card-full">
            <div className="card-title-group pb-3 g-2">
              <div className="card-title card-title-sm">
                <h6 className="title">Global Score</h6>
              </div>
            </div>
            <Card className="card-bordered">
              <RadarChartTrader data={RadarData}></RadarChartTrader>
            </Card>
            {/*<InvestPlan />
            <div className="nk-divider divider md"></div>
              <div className="card-title-group pb-3 g-2">
                <div className="card-title card-title-sm">
                  <h6 className="title">Historical Scores</h6>
                </div>
              </div>
              <Card className="card-bordered">
                <RadarChartTrader data={RadarData}></RadarChartTrader>
              </Card>
              */}
          </PreviewAltCard>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default KnowYourTrader;
