import React, { useState, useEffect } from 'react';
import axios from "../../../../api/axios";
import {Alert} from "reactstrap";
import Icon from "../../../../assets/components/icon/Icon";
import DatePicker from "react-datepicker";
import moment from 'moment';
import { useNavigate } from "react-router-dom"


const TradingSystemsTable = ({ data}) => {

    const navigate = useNavigate();
    const [portfolionames, setportfolioNames] = useState(JSON.parse(localStorage.getItem("portfolios")));
    const [accountnames, setaccountnames] = useState(JSON.parse(localStorage.getItem("accounts")));
    useEffect(() => {
        let x = data.sort((a, b) => (a.system_id < b.system_id ? 1 : -1));
        data = x;
    }, []);

    const renderActiveAlertsHeader = ()=> {
        let headerElement = ['#', 'Trader', 'System', 'Type', 'Active', 'Product Category', 'AUM current', 'AUM max', 'Profit Factor', 'AVG Return Month', 'AVG DD', 'MAX DD', 'Trading System Quality']

        return headerElement.map((key, index) => {
            return <th key={index} style={{whiteSpace:'nowrap'}}>{key.toUpperCase()}</th>
        })
    }

    function currencyFormat(amount){
        return (amount).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    }
    const renderActiveAlertsBody = () => {
        return data && data.map(({ system_id, system_name, system_type, product_category, trader_name, aum_trading_system, aum_trading_system_max, system_profit_factor, system_avg_monthly_return, system_avg_dd, system_max_dd, system_quality_score}) => {
            return (
                <tr key={system_id} onClick={(ev) => {
                    ev.preventDefault();
                    navigate("/trader-performance/" + system_id.toString());
                }}>
                    <td className='text-center'>{system_id}</td>
                    <td>{trader_name}</td>
                    <td>{system_name}</td>
                    <td>{system_type}</td>
                    {aum_trading_system > 0 ?(
                        <td>YES</td>
                    ):(<td>NO</td>)}
                    <td className='text-center'>{product_category}</td>
                    <td className='text-center'>{currencyFormat(aum_trading_system)}</td>
                    <td className='text-center'>{currencyFormat(aum_trading_system_max)}</td>
                    <td className='text-center'>{system_profit_factor.toFixed(2)}</td>
                    <td className='text-center'>{(system_avg_monthly_return*100).toFixed(2)}</td>
                    <td className='text-center'>{(system_avg_dd*100).toFixed(2)}</td>
                    <td className='text-center'>{(system_max_dd*100).toFixed(2)}</td>
                    <td className='text-center'>{(system_quality_score).toFixed(2)}</td>
                </tr>
            )
        })
    }

    return (

           <div className='table-responsive'>
           <table className="table">
                <thead>
                <tr>{renderActiveAlertsHeader()}</tr>
                </thead>
                <tbody>
                {renderActiveAlertsBody()}
                </tbody>
            </table>
           </div>
    )
}

export default TradingSystemsTable