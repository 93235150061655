import React, { useState, useEffect } from 'react'
import axios from "../../../../api/axios";
import {Alert} from "reactstrap";
import Icon from "../../../../assets/components/icon/Icon";
import {useParams} from "react-router-dom";


const ALERTS = '/api/rtm/alerts/';

const GET_SYMBOLS = '/api/rtm/allowed_symbols/';
const ADD_SYMBOL = '/api/rtm/allowed_symbols/';

const Alert_test = () => {
    const params = useParams()
    const [choosenAlertTab, setAlertTab] = useState("create");
    const [activeAlerts, setactiveAlerts] = useState([]);
    const [AllowedSymbols, setAllowedSymbols] = useState([]);
    const [AddSymboltext, setAddSymboltext] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const [createAlertflex1, setAlertflex1] = useState("Threshold");
    const [createAlertflex2, setAlertflex2] = useState("Threshold");
    const [alerttype, setAlertype] = useState('Alert1');
    const [alertcrit, setAlercrit] = useState('Information');
    const [alertdesc, setAlertdesc] = useState('');
    const [alertthreshold, setAlertthreshold] = useState('');

    const [alertThreshold2, setAlerThreshold2] = useState(0);

    useEffect(() => {
        setErrMsg('');
    }, [alertThreshold2, alertdesc, alertthreshold])

    useEffect(() => {
        async function fetchAllowedSymbols() {
            try {
                var token = "";
                if (typeof window !== 'undefined') {
                    token = localStorage.getItem('Token');
                }
                const response = await axios.get(GET_SYMBOLS  + params.userId, {headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Authorization': ['Bearer ', token].join('')
                    }});

                setAllowedSymbols(await response?.data);
            }
            catch (e)
            {
                console.log(e);
            }
        }
        async function fetchActiveAlerts() {
            try {
                var token = "";
                if (typeof window !== 'undefined') {
                    token = localStorage.getItem('Token');
                }
                const response = await axios.get(ALERTS  + params.userId, {headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Authorization': ['Bearer ', token].join('')
                    }});

                setactiveAlerts(await response?.data);
            }
            catch (e)
            {
                console.log(e);
            }
        }


        fetchAllowedSymbols();
        fetchActiveAlerts();

    }, [])

    useEffect( () => {

        if (alerttype == "Alert9")
        {
            setAlertflex1("Upper Threshold");
            setAlertflex2("Lower Threshold");
            var x = document.getElementById("thresholddiv2");
            x.style.display = "block";
        }
        else
        {
            setAlertflex2("Threshold");
            var x = document.getElementById("thresholddiv2");
            x.style.display = "none";
        }

    }, [alerttype])

    const sendcreateAlert = async (e) => {
        e.preventDefault();
        async function fetchActiveAlerts() {
            try {
                var token = "";
                if (typeof window !== 'undefined') {
                    token = localStorage.getItem('Token');
                }
                const response = await axios.get(ALERTS  + params.userId, {headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Authorization': ['Bearer ', token].join('')
                    }});

                setactiveAlerts(await response?.data);
            }
            catch (e)
            {
                console.log(e);
            }
        }

        if (alerttype == "Alert9" && alertthreshold == "" && alertThreshold2=="")
        {
            setErrMsg('Please Fill the Date Field!');
        }
        else if(alerttype == "Alert9" && alertThreshold2 == "")
        {
            setErrMsg('Please Fill the second Threshold Field!');
        }
        else if(alertdesc == "" || alertthreshold == "")
        {
            setErrMsg('Please enter a Description and a Threshold!');
        }

        if (errMsg == "")
        {
            try {
                var token = "";
                if (typeof window !== 'undefined') {
                    token = localStorage.getItem('Token');
                }

                const response = await axios.post(ALERTS, {
                        "alert_account": params.userId,
                        "alert_type": alerttype,
                        "alert_desc": alertdesc,
                        "criticality": alertcrit,
                        "threshold1": alertthreshold,
                        "threshold2": alertThreshold2,
                        "alert_action": "none",
                    },
                    {headers: {
                            'Content-Type': 'application/json',
                            'Authorization': ['Bearer ', token].join('')
                        }});
                console.log(JSON.stringify(response?.data));
                fetchActiveAlerts();
                document.getElementById('alertdesc').value = "";
                document.getElementById('threshold1').value = "";
                document.getElementById('threshold2').value = "";
                setAlerThreshold2(0);
                setAlertthreshold(0);

                setAlertype("Alert1");
                document.getElementById("alertype").value = "Alert1";


            }
            catch (err)
            {
                console.log(err);
            }

        }


    }

    const add_allowed_symbol = async (e) => {

        async function fetchAllowedSymbols() {
            try {
                var token = "";
                if (typeof window !== 'undefined') {
                    token = localStorage.getItem('Token');
                }
                const response = await axios.get(GET_SYMBOLS  + params.userId, {headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Authorization': ['Bearer ', token].join('')
                    }});

                setAllowedSymbols(await response?.data);
            }
            catch (e)
            {
                console.log(e);
            }

        }


        e.preventDefault();
        if(AddSymboltext != "")
        {
            try {
                var token = "";
                if (typeof window !== 'undefined') {
                    token = localStorage.getItem('Token');
                }

                const response = await axios.post(ADD_SYMBOL, {
                        "trading_account_id": params.userId,
                        "symbols": AddSymboltext,
                    },
                    {headers: {
                            'Content-Type': 'application/json',
                            'Authorization': ['Bearer ', token].join('')
                        }});
                console.log(JSON.stringify(response?.data));
                fetchAllowedSymbols();

            }
            catch (err)
            {
                console.log(err);
            }
        }
    }

    const removeAllowedSymbol = (id) => {

        var token = "";
        if (typeof window !== 'undefined') {
            token = localStorage.getItem('Token');
        }
        axios.delete(GET_SYMBOLS  + id, {headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': ['Bearer ', token].join('')
            }}).then(res => {
            const del = AllowedSymbols.filter(item => id !== item.combination_id)
            setAllowedSymbols(del)
        });
    }

    const removeActiveAlert = (id) => {

        var token = "";
        if (typeof window !== 'undefined') {
            token = localStorage.getItem('Token');
        }
        axios.delete(ALERTS  + id, {headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': ['Bearer ', token].join('')
            }}).then(res => {
            const del = activeAlerts.filter(item => id !== item.alert_id)
            setactiveAlerts(del)
        });
    }

    const renderallowedSymbolHeader = ()=> {
        let headerElement = ['#', 'Symbol', 'Delete']

        return headerElement.map((key, index) => {
            return <th key={index}>{key.toUpperCase()}</th>
        })
    }

    const renderActiveAlertsHeader = ()=> {
        let headerElement = ['#', 'Alert Type', 'Description', 'Criticality', 'Threshold', 'Threshold 2', 'Action', 'Delete']

        return headerElement.map((key, index) => {
            return <th key={index}>{key.toUpperCase()}</th>
        })
    }



    const renderallowedSymbolBody = () => {
        return AllowedSymbols && AllowedSymbols.map(({ combination_id, symbols}) => {
            return (
                <tr key={combination_id}>
                    <td>{combination_id}</td>

                    <td>{symbols}</td>
                    <td className='opration'>
                        <button className='button' onClick={() => removeAllowedSymbol(combination_id)}>Delete</button>
                    </td>
                </tr>
            )
        })
    }

    const renderActiveAlertsBody = () => {
        return activeAlerts && activeAlerts.map(({ alert_id, alert_account, alert_type, alert_desc, criticality, threshold1, threshold2, alert_action}) => {
            return (
                <tr key={alert_id}>
                    <td>{alert_id}</td>
                    <td>{alert_type}</td>
                    <td>{alert_desc}</td>
                    <td>{criticality}</td>
                    <td>{threshold1}</td>
                    <td>{threshold2}</td>
                    <td>{alert_action}</td>
                    <td className='opration'>
                        <button className='button' onClick={() => removeActiveAlert(alert_id)}>Delete</button>
                    </td>
                </tr>
            )
        })
    }


    const changeAlertType = (e) => {

        setAlertype(e.target.value);
        console.log(e.target.value);
        if (e.target.value == "Alert9")
        {
            console.log(e.target.value);
            setAlertflex1("Upper Threshold");
            setAlertflex2("Lower Threshold");
            var x = document.getElementById("thresholddiv2");
            x.style.display = "block";
        }
        else
        {
            setAlertflex2("Threshold");
            var x = document.getElementById("thresholddiv2");
            x.style.display = "none";
        }
    }




    return (
        <React.Fragment>
            <div className="card-inner">
                <div className="card-title-group">
                    <div className="card-title card-title-sm">
                        <h6 className="title">Alerts</h6>
                    </div>
                    <div className="card-tools">
                        <ul className="card-tools-nav">
                            <li className={choosenAlertTab === "create" ? "active" : ""} onClick={() => setAlertTab("create")}>
                                <a
                                    href="#create"
                                    onClick={(ev) => {
                                        ev.preventDefault();
                                    }}
                                >
                                    <span>Create Alert</span>
                                </a>
                            </li>
                            <li className={choosenAlertTab === "activealerts" ? "active" : ""} onClick={() => setAlertTab("activealerts")}>
                                <a
                                    href="#activealerts"
                                    onClick={(ev) => {
                                        ev.preventDefault();
                                    }}
                                >
                                    <span>Active Alerts</span>
                                </a>
                            </li>
                            <li className={choosenAlertTab === "symbols" ? "active" : ""} onClick={() => setAlertTab("symbols")}>
                                <a
                                    href="#symbols"
                                    onClick={(ev) => {
                                        ev.preventDefault();
                                    }}
                                >
                                    <span>Allowed Symbols</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            {choosenAlertTab == "create" ? (
                <div className="card-inner">
                    <form action="#" onSubmit={sendcreateAlert}>
                        <div className="row g-4">
                            <div className="col-lg-6">
                                <div className="form-group"><label className="form-label" htmlFor="full-name-1">Alert Type</label>
                                    <div className="form-control-wrap">

                                        <select name="alertype" className="form-control" id="alertype" onChange={(e) => changeAlertType(e)}>
                                            <option value="Alert1">Balance below Threshold</option>
                                            <option value="Alert13">Balance above Threshold</option>
                                            <option value="Alert2">Equity below Threshold</option>
                                            <option value="Alert14">Equity above Threshold</option>
                                            <option value="Alert3">Margin % below Threshold</option>
                                            <option value="Alert4">Total open Lot above</option>
                                            <option value="Alert5">Total open Loss great than</option>
                                            <option value="Alert6">Total closed Loss great than</option>
                                            <option value="Alert7">Daily closed Loss great than</option>
                                            <option value="Alert8">No Trading Activity in the last Days</option>
                                            <option value="Alert9">Performance Corridor</option>
                                            <option value="Alert10">Balance below or above % Threshold</option>
                                            <option value="Alert11">Weekly Profit/Loss Target % reached</option>
                                            <option value="Alert12">Monthly Profit/Loss Target % reached</option>
                                            <option value="Alert15">Profit Factor below Threshold</option>
                                            <option value="Alert16">Total open Position above Threshold</option>
                                            <option value="Alert17">% Risk per Trade, based on Equity</option>
                                            <option value="Alert18">Floating Equity % exposure</option>
                                            <option value="Alert19">Deposit / Withdrawal above Threshold</option>
                                            <option value="Alert20">No Deposit / Withdrawal for more than x Days</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="form-group"><label className="form-label" htmlFor="email-address-1">Criticality</label>
                                    <div className="form-control-wrap">

                                        <select name="criticality" className="form-control" id="criticality" onChange={(e) => setAlercrit(e.target.value)}>
                                            <option value="Information">Information</option>
                                            <option value="High">Action</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="form-group"><label className="form-label" htmlFor="phone-no-1">Description</label>
                                    <div className="form-control-wrap"><input type="text" className="form-control"
                                                                              id="alertdesc" onChange={(e) => setAlertdesc(e.target.value)}/></div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="form-group"><label className="form-label" htmlFor="pay-amount-1">{createAlertflex2}</label>
                                    <div className="form-control-wrap"><input type="text" className="form-control"
                                                                              id="threshold1" onChange={(e) => setAlertthreshold(e.target.value)}/></div>
                                </div>
                            </div>



                            <div className="col-lg-6" id="thresholddiv2" style={{display: 'none'}}>
                                <div className="form-group"><label className="form-label" htmlFor="pay-amount-1">{createAlertflex1}</label>
                                    <div className="form-control-wrap"><input type="text" className="form-control"
                                                                              id="threshold2" onChange={(e) => setAlerThreshold2(e.target.value)}/></div>
                                </div>
                            </div>


                            {errMsg && (
                                <div className="col-12">
                                    <Alert color="danger" className="alert-icon">
                                        {" "}
                                        <Icon name="alert-circle" /> {errMsg}
                                    </Alert>
                                </div>
                            )}
                            <div className="col-12">
                                <div className="form-group">
                                    <button className="btn btn-lg btn-primary" type="submit">Create Alert</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            ): choosenAlertTab == "symbols" ? (
                    <div className="card-inner">


                        <table className="table" id='allowed_symbols'>
                            <thead>
                            <tr>{renderallowedSymbolHeader()}</tr>
                            </thead>
                            <tbody>
                            {renderallowedSymbolBody()}
                            </tbody>
                        </table>
                        <form action="#" onSubmit={add_allowed_symbol}>
                            <div className="row g-6">
                                <div className="col-lg-3">
                                    <div className="form-group"><label className="form-label"
                                                                       htmlFor="phone-no-1">Add Symbol</label>
                                        <div className="form-control-wrap"><input type="text" className="form-control"
                                                                                  id="alertdesc"
                                                                                  onChange={(e) => setAddSymboltext(e.target.value)}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-log-3">
                                    <div className="form-control-wrap">
                                        <label className="form-label"
                                               htmlFor="phone-no-1" ><wbr></wbr> </label>
                                        <div className="form-control-wrap">
                                            <button className="btn btn-round btn-sm btn-primary" type="submit">Add Symbol</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
            ) : (
                <div className="card-inner">
                    <table className="table">
                        <thead>
                        <tr>{renderActiveAlertsHeader()}</tr>
                        </thead>
                        <tbody>
                        {renderActiveAlertsBody()}
                        </tbody>
                    </table>
                </div>
            )}

        </React.Fragment>
    )
}


export default Alert_test