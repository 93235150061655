import React, {useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import Icon from '../../../../assets/components/icon/Icon';
import {AudienceLineChart} from "../balance_equity_chart/BalanceChart";
import {
    Alert,
    Card,
    CardTitle, DropdownItem, DropdownMenu, DropdownToggle,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Progress,
    UncontrolledDropdown
} from "reactstrap";
import axios from "../../../../api/axios";
import {Doughnut, Bar} from "react-chartjs-2";

import {
    Button,
    PreviewAltCard,
} from "../../../../assets/components/Component";
import {Context} from "../../../Trader_performance/Context";
import Form_newTrader from "../../../Trader_performance/Components/CreateTraderSystem/FormCreateTrader";
import Form_newSystem from "../../../Trader_performance/Components/CreateTraderSystem/FormCreateSystem";


const WS_URL = "wss://websocket.nobleskies.com/ws";
//const WS_URL = "ws://127.0.0.1:6789";

const LOG_ENDPOINT = '/api/ks/post_action_log/';
function Killswitchtest() {
    const [datarecieved, setdatarecieved] = useState(false);
    const [socket, setSocket] = useState(null);
    const params = useParams()

    const [trading_account, setTradingAccount] = useState(params.userId.toString())
    const [isconnected, setisconnected] = useState(false);
    const [emergencystopoutreached, setemergencystopoutreached] = useState(false);
    const [equitythreshold, setequitythreshold] = useState(undefined)
    const [drawdownthreshold, setdrawdownthreshold] = useState(undefined);
    const [showWizard, setShowWizard] = useState(null);
    const [currentStep, setCurrentStep] = useState(1);
    const [inputequitythreshold, setinputthreshold] = useState(0);
    const [inputdrawdownthreshold, setinputdrawdownthreshold] = useState(0);
    const [sm, updateSm] = useState(false);
    const [socketdata, setsocketdata] = useState({
        "RealtimeStats": "AccountNo",
        "Time": "not connected",
        "Balance": "0",
        "Equity": "0",
        "NoOfOpenTrades": "0",
        "NoOfOpenLots": "0",
        "NoOfOpenOrders": "0",
        "NoOfOpenOrdersLots": "0.00",
        "NoOfClosedTrades": "0",
        "UsedMargin": "0",
        "FreeMargin": "0",
        "MarginLevel": "0",
        "Deposit": "0.00",
        "NoOfOpenCharts": "1!"
    });
    const [response, setResponse] = useState("");
    const [killswitchLogAction, setkillswitchLogAction] = useState("");
    const [killswitchLogThreshold, setkillswitchLogThreshold] = useState("");
    useEffect(() => {
        setCurrentStep(showWizard);
    }, [showWizard]);

    useEffect(() => {
        console.log(inputequitythreshold);
    }, [inputequitythreshold]);

    useEffect(() => {
        if (parseFloat(equitythreshold) > parseFloat(socketdata.Equity) && parseFloat(equitythreshold) > 0 && parseFloat(socketdata.Equity) > 0)
        {
            setemergencystopoutreached(true);
        }
        else {
            setemergencystopoutreached(false);
        }
    }, [equitythreshold, socketdata]);


    function sendEquity() {
        var command = "Prompt," + trading_account + ",GetMinimumEquity!";
        socket.send(command);
    }

    function sendDrawdown() {
        var command = "Prompt," + trading_account + ",GetMaximumDrawdown!";
        socket.send(command);
    }

    function modalconfirm() {
        if (currentStep == 1) {
            var command = "Prompt," + trading_account + ",SetMinimumEquity," + inputequitythreshold;
            socket.send(command);
            console.log(command);

            creatLog(command, "SetMinimumEquity");
            sendEquity();

        } else if (currentStep == 2) {
            var command = "Prompt," + trading_account + ",SetMaximumDrawdown," + inputdrawdownthreshold;
            socket.send(command);
            console.log(command);
            creatLog(command, "SetMaximumDrawdown");
            sendDrawdown();

        } else if (currentStep == 3) {
            var command = "Prompt," + trading_account + ",SetMinimumEquity," + socketdata.Equity + 500;
            socket.send(command);
            console.log(command);
            creatLog(command, "CloseAllPositions");
            sendEquity();
            setemergencystopoutreached(true);

        }
        setShowWizard(false);
    }




    function onMessage(e) {
        var socketdata = e.data;
        if (typeof socketdata === 'string') {
            try {

                if (socketdata.includes("data")) {
                    socketdata = JSON.parse(socketdata);
                    if (socketdata["account"] == trading_account)
                    {
                        setsocketdata(socketdata["data"]);
                        setdatarecieved(true);
                    }

                } else if (socketdata.includes("equitythreshold")) {
                    var socketdata = JSON.parse(socketdata);
                    if (socketdata["account"] == trading_account)
                    {
                        setequitythreshold(socketdata["equitythreshold"]);
                    }

                } else if (socketdata.includes("drawdownhreshold")) {
                    var socketdata = JSON.parse(socketdata);

                    if (socketdata["account"] == trading_account)
                    {
                        setdrawdownthreshold(socketdata["drawdownhreshold"]);
                    }

                }

//                setsocketdata(socketdata["data"]);
            } catch (e) {
                console.log(e)
                console.log(e.data)
            }

        }
    }

    useEffect(() => {
        const newsocket = new WebSocket(WS_URL);
        newsocket.onmessage = onMessage;
        newsocket.onopen = () => {
            console.log("socket connection opened")
            setisconnected(true)
            var commandprompt = "Prompt," + trading_account + ",GetMinimumEquity!";
            newsocket.send(commandprompt);
            commandprompt = "Prompt," + trading_account + ",GetMaximumDrawdown!";
            newsocket.send(commandprompt);

        }
        setSocket(newsocket);


    }, []);

    const wizardSteps = [
        {
            id: 1,
            element: (
                <>
                    <ModalHeader toggle={() => setShowWizard(null)}>
                        Set Minimum Equity Threshold [Absolute Value]
                    </ModalHeader>
                    <ModalBody>
                        <Card className="card-bordered card-full">
                            <p>Setting the Equity Threshold will activate the Trade Guardian monitoring based on Account Equity. Once the Account Equity falls below the Equity Threshold all open Positions will be closed and Trading will be paused. Please proceed with caution.</p>
                            <input className="form-control" type="number"
                                   placeholder="Please provide a Equity Threshold"
                                   onChange={(e) => setinputthreshold(e.target.value)}></input>
                        </Card>
                    </ModalBody>
                </>
            ),
        },
        {
            id: 2,
            element: (
                <>
                    <ModalHeader toggle={() => setShowWizard(null)}>
                        Set Maximum Drawdown Threshold [%]
                    </ModalHeader>
                    <ModalBody>
                        <Card className="card-bordered card-full">
                            <p>Setting the Drawdown Threshold will activate the Trade Guardian monitoring based on % Drawdown. Once the Account Drawdown falls below the Drawdown Threshold all open Positions will be closed and Trading will be paused. Please proceed with caution.</p>
                            <input className="form-control" type="number"
                                   placeholder="Please provide a Drawdown Threshold"
                                   onChange={(e) => setinputdrawdownthreshold(e.target.value)}></input>
                        </Card>
                    </ModalBody>
                </>
            ),
        },
        {
            id: 3,
            element: (
                <>
                    <ModalHeader toggle={() => setShowWizard(null)}>
                        Are you sure you want to close all Positions?
                    </ModalHeader>
                    <ModalBody>
                            <p>
                                Positions will be closed and unrealized profit will be realized. Please proceed only if you wish to close all open positions.</p>

                    </ModalBody>
                </>

            ),
        },
    ];

    const creatLog = async (command, action, e) => {
        //e.preventDefault();

        try {
            console.log("test");
            console.log(command);
            var token = "";
            if (typeof window !== 'undefined') {
                token = localStorage.getItem('Token');
            }

            const response = await axios.post(LOG_ENDPOINT, {
                    "account": Number(trading_account),
                    "action": action,
                    "threshold": command
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': ['Bearer ', token].join('')
                    }
                });
            console.log(await response);

        } catch (err) {
            console.log(err);
        }

    }

    return (
        <React.Fragment>
            <Modal
                centered
                scrollable
                isOpen={showWizard ? true : false}
                toggle={() => setShowWizard(null)}
            >

                {wizardSteps.map((ws) => {
                    if (ws.id === currentStep) {
                        return (
                            <React.Fragment key={ws.id}>{ws.element}</React.Fragment>
                        );
                    }
                })}
                <ModalFooter>
                    <Button color="danger" onClick={() => setShowWizard(false)}>
                        Cancel
                    </Button>
                    <Button color="success" onClick={() => modalconfirm()}>
                        Confirm
                    </Button>
                </ModalFooter>
            </Modal>

            <div className="card-title-group align-start mb-3">
            <div className="card-title-group align-start pb-3 g-2">
                <div className="card-title card-title-sm">
                    <h6 className="title">
                        {isconnected == true ? (
                                <div className="status dot dot-lg dot-success"></div>
                            ) :
                            (
                                <div className="status dot dot-lg dot-danger"></div>
                            )}
                        <span> Kill Switch</span></h6>

                </div>


            </div>

            <div className="card-tools mt-n1 mr-n1">
                <UncontrolledDropdown>
                    <DropdownToggle tag="a" className="dropdown-toggle btn btn-icon btn-trigger">
                        <Icon name="more-h"></Icon>
                    </DropdownToggle>
                    <DropdownMenu right>
                        <ul className="link-list-opt no-bdr">
                            <li className={"orderOverview" === "" ? "active" : ""}>
                                <DropdownItem
                                    tag="a"
                                    href="#dropdownitem"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setShowWizard(1);


                                    }}
                                >
                                    <span>Set Equity Threshold</span>
                                </DropdownItem>
                            </li>
                            <li className={"orderOverview" === "set2" ? "active" : ""}>
                                <DropdownItem
                                    tag="a"
                                    href="#dropdownitem"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setShowWizard(2);
                                    }}
                                >
                                    <span>Set Drawdown Threshold</span>
                                </DropdownItem>
                            </li>
                            <li className={"orderOverview" === "set2" ? "active" : ""}>
                                <DropdownItem
                                    tag="a"
                                    href="#dropdownitem"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setShowWizard(3);
                                    }}
                                >
                                    <span>Close all Positions</span>
                                </DropdownItem>
                            </li>
                        </ul>
                    </DropdownMenu>
                </UncontrolledDropdown>
            </div>
            </div>
            <div className="card-inner">

                {datarecieved == false ? (
                    <p>Waiting for Data from MT4...</p>
                ):(
                    <Card className="card-bordered">
                        {emergencystopoutreached == true ? (
                                <div className="alert alert-icon alert-danger" role="alert"><em className="icon ni ni-alert-circle"></em> <strong>Emergency Stopout reached!</strong> All Positions haven been closed.</div>
                            )
                        :(<div></div>)}

                        <ul className="data-list is-compact">
                            <li className="data-item">
                                <div className="data-col">
                                    <div className="data-label">Last update</div>
                                    <div className="data-value">
                                        {socketdata.Time}
                                    </div>
                                </div>
                            </li>
                            <li className="data-item">
                                <div className="data-col">
                                    <div className="data-label">Balance</div>
                                    <div className="data-value">
                                        {socketdata.Balance}
                                    </div>
                                </div>
                            </li>
                            <li className="data-item">
                                <div className="data-col">
                                    <div className="data-label">Equity</div>
                                    <div className="data-value text-soft">
                                        <em>
                        <span>
                          {socketdata.Equity}
                        </span>
                                        </em>
                                    </div>
                                </div>
                            </li>
                            <li className="data-item">
                                <div className="data-col">
                                    <div className="data-label">used Margin</div>
                                    <div className="data-value text-soft">
                                        <em>
                        <span>
                          {socketdata.UsedMargin}
                        </span>
                                        </em>
                                    </div>
                                </div>
                            </li>
                            <li className="data-item">
                                <div className="data-col">
                                    <div className="data-label">free Margin</div>
                                    <div className="data-value text-soft">
                                        <em>
                        <span>
                          {socketdata.FreeMargin}
                        </span>
                                        </em>
                                    </div>
                                </div>
                            </li>
                            <li className="data-item">
                                <div className="data-col">
                                    <div className="data-label">Margin Level</div>
                                    <div className="data-value text-soft">
                                        <em>
                        <span>
                          {socketdata.MarginLevel}
                        </span>
                                        </em>
                                    </div>
                                </div>
                            </li>
                            <li className="data-item">
                                <div className="data-col">
                                    <div className="data-label">open Lots</div>
                                    <div className="data-value text-soft">
                                        <em>
                        <span>
                          {socketdata.NoOfOpenLots}
                        </span>
                                        </em>
                                    </div>
                                </div>
                            </li>
                            <li className="data-item">
                                <div className="data-col">
                                    <div className="data-label">Equity Threshold</div>
                                    <div className="data-value text-soft">
                                        <em>
                        <span>
                          {equitythreshold}
                        </span>
                                        </em>
                                    </div>
                                </div>
                            </li>
                            <li className="data-item">
                                <div className="data-col">
                                    <div className="data-label">Drawdown Threshold</div>
                                    <div className="data-value text-soft">
                                        <em>
                        <span>
                          {drawdownthreshold}
                        </span>
                                        </em>
                                    </div>
                                </div>
                            </li>
                        </ul>
                        <div className="text-center pt-3">
                            <a href="#" className="btn btn-danger" onClick={(e) => {
                                e.preventDefault();
                                setShowWizard(3);
                            }}>Emergency Close</a>
                        </div>

                    </Card>
                    )}


            </div>
        </React.Fragment>
    );
}

export default Killswitchtest;

