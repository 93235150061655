import React, { useEffect, useState } from "react";
import { DataTableBody, DataTableHead, DataTableItem, DataTableRow } from "../../../../assets/components/Component";
import {
    Block,
    BlockHead,
    BlockHeadContent,
    BlockTitle,
    BlockDes,
    BackTo,
    PreviewCard,
    ReactDataTable,
} from "../../../../assets/components/Component";
import { Link, useParams } from "react-router-dom";
import axios from "../../../../api/axios";
const ACCOUNT_STATEMENT = '/api/rtm/account_statement/';



const Account_Statement = () => {


    const [isLoading, setLoading] = useState(true);
    const [tabledata, setTableData] = useState([]);
    const dataTableColumns = [
        {
            name: "Account",
            selector: (row) => row.account_number,
            sortable: true,
            hide: "sm",
        },
        {
            name: "Date",
            selector: (row) => row.timestamp,
            sortable: true,
            hide: 370,
        },
        {
            name: "Balance",
            selector: (row) => row.balance.toFixed(2),
            sortable: true,
            hide: "sm",
        },
        {
            name: "Equity",
            selector: (row) => row.equity.toFixed(2),
            sortable: true,
            hide: "sm",
        },
        {
            name: "open pnl",
            selector: (row) => row.profit.toFixed(2),
            sortable: true,
            hide: "md",
        },
        {
            name: "margin",
            selector: (row) => row.margin.toFixed(2),
            sortable: true,
            hide: "md",
        },
    ];



    const params = useParams()

    useEffect(() => {
        async function fetchAccountStatement() {
            try {
                var token = "";
                if (typeof window !== 'undefined') {
                    token = localStorage.getItem('Token');
                }
                const response = await axios.get(ACCOUNT_STATEMENT + params.userId, {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Authorization': ['Bearer ', token].join('')
                    }
                });

                setTableData(await response?.data);


            }
            catch (e) {
                console.log(e);
            }
        }

        fetchAccountStatement();


    }, []);

    useEffect( () =>{
        console.log(tabledata);
        if (tabledata.length > 0)
        {
            setLoading(false);
            console.log(tabledata);
        }
    }, [tabledata])





    if (isLoading == false) {
        return (
            <React.Fragment>
                <div className="card-inner">
                    <div className="card-title-group">
                        <div className="card-title card-title-sm">
                            <h6 className="title">Daily Account Statement</h6>
                        </div>
                        <div className="card-tools">
                        </div>
                    </div>
                </div>
                <Block size="lg">



                    <PreviewCard>
                        <ReactDataTable data={tabledata} columns={dataTableColumns} keyField="ticket" actions pagination />
                    </PreviewCard>



                </Block>
            </React.Fragment>
        )
    }
}
export default Account_Statement;


