import React, { useState, useEffect, Component } from "react";
import { useParams } from "react-router-dom";
import moment from 'moment';
import { FTT_RETURNLineChart } from "./TChart.js";
import axios from "../../../../api/axios";
import { Line } from "react-chartjs-2";
import _ from 'lodash'


class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    componentDidCatch(error, info) {
        this.setState({ hasError: true });
        // You can also log the error to a logging service
        console.error(error);
    }

    render() {
        if (this.state.hasError) {
            // You can render a custom error message here
            return <div>Something went wrong.</div>;
        }
        return this.props.children;
    }
}

const FTTChartOverview = ({ fttuser, accountlist }) => {

    const [roiPerc, setroiPerc] = useState([])
    const [accountdata, setAccountData] = useState([])
    const [roiPercweighted, setroiPercweighted] = useState([])
    const [chartType, setChartType] = useState("Weighted Returns");


    const randomColor = function () {
        const r = Math.floor(Math.random() * 255);
        const g = Math.floor(Math.random() * 255);
        const b = Math.floor(Math.random() * 255);
        return `rgb(${r},${g},${b})`
    }



    useEffect(() => {
        const fetchAccount = async (i) => {
            if (!fttuser && !accountlist.length) return

            let token = ''
            if (typeof window !== 'undefined') {
                token = localStorage.getItem('Token');
            }

            const arr = []
            const acctmp = [];

            for (let i = 0; i < accountlist.length; i++) {
                const { data } = await axios.get(`/api/rtm/ftt_return_chart_data/${accountlist[i]}/${fttuser}`, {
                    headers: {
                        'Authorization': ['Bearer ', token].join('')
                    }
                })

                if (data.length) {
                    console.log(data);
                    arr.push({
                        roi: data.map(d => d.roi_perc),
                        date: data.map(d => d.date),
                        mt4_accountname: data[0].mt4_accountname
                    });

                    acctmp.push(data);
                }
            }
            setroiPerc(arr);
            setAccountData(acctmp);

        }

        fetchAccount()

    }, [fttuser, accountlist])



    useEffect(() => {

        if (accountdata.length > 0)
        {

            var lenghts = 0;
            var refaccount = 0;
            var looplist = [];

            for (let i = 0; i < accountdata.length; i++) {
                if (accountdata[i].length > lenghts)
                {
                    lenghts = accountdata[i].length;
                    refaccount = i;
                }
            }

            for (let x = 0; x < accountdata.length; x++) {
                if (x != refaccount)
                {
                    looplist.push(x);
                }
            }


            var testdata = [];
            for (let as = 0; as < accountdata[refaccount].length; as++) {
                var totalbalance = accountdata[refaccount][as].balance;
                var datum = accountdata[refaccount][as].date;

                for(var ix in looplist){

                    const filtered = accountdata[ix].filter(item => {
                        return item.date == datum;
                    });
                    totalbalance = totalbalance + filtered[0].balance;
                }
                testdata.push({
                    datum: datum,
                    totalbalance: totalbalance
                })

            }

            var accountdatatemp = [];

            for (let x = 0; x < accountdata.length; x++) {

                var inneraccountdatatemp = []
                for (let ix = 0; ix < accountdata[x].length; ix++) {

                    var datatemp2 = accountdata[x][ix];

                    //console.log(datatemp2);
                    //console.log(testdata);
                    const filtered = testdata.filter(item => {
                        return item.datum == datatemp2["date"];
                    });

                    datatemp2["weighted"] = (datatemp2["balance"] / filtered[0]["totalbalance"]) * datatemp2["roi_perc"]
                    inneraccountdatatemp.push(datatemp2);
                }
                accountdatatemp.push(inneraccountdatatemp);

            }



            const arr = []
            const acctmp = [];

            for (let i = 0; i < accountdatatemp.length; i++) {

                var data = accountdatatemp[i];

                if (data.length) {
                    arr.push({
                        roi_weighted: data.map(d => d.weighted),
                        date: data.map(d => d.date),
                        mt4_accountname: data[0].mt4_accountname
                    });


                }
            }

            setroiPercweighted(arr);




        }
    }, [accountdata])

    const data = {
        labels: roiPerc[0]?.date,
        datasets: roiPerc.map(r => {
            return {
                label: r.mt4_accountname,
                data: r.roi || [],
                borderColor: roiPerc.length > 1 ? randomColor() : '#816bff',
            }
        })
    };

    const dataweighted = {
        labels: roiPercweighted[0]?.date,
        datasets: roiPercweighted.map(r => {
            return {
                label: r.mt4_accountname,
                data: r.roi_weighted || [],
                borderColor: roiPercweighted.length > 1 ? randomColor() : '#816bff',
            }
        })
    };

    const options = {
        tooltips: {
            enabled: true,
            callbacks: {
                title: function (tooltipItem, data) {
                    return false;
                },
                label: function (tooltipItem, data) {
                    return data.datasets[tooltipItem.datasetIndex]["data"][tooltipItem["index"]];
                },
            },
            backgroundColor: "#eff6ff",
            titleFontSize: 9,
            titleFontColor: "#6783b8",
            titleMarginBottom: 6,
            bodyFontColor: "#9eaecf",
            bodyFontSize: 9,
            bodySpacing: 4,
            yPadding: 6,
            xPadding: 6,
            footerMarginTop: 0,
            displayColors: false,
        },
    }

    return (
        <ErrorBoundary>
        <React.Fragment>

            {
                roiPerc.length
                    ? (
                        <div className="card-inner">
                            <div className="card-title-group">
                                <div className="card-title card-title-sm">
                                    <h6 className="title">{chartType}</h6>
                                </div>
                                <div className="card-tools shrink-0 d-none d-sm-block">
                                    <ul className="nav nav-switch-s2 nav-tabs bg-white">
                                        <li className="nav-item" id="1M">
                                            <a
                                                href="#navitem"
                                                className={chartType === "Account Returns" ? "nav-link active" : "nav-link"}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setChartType("Account Returns");
                                                }}
                                            >
                                                Account Returns
                                            </a>
                                        </li>
                                        <li className="nav-item" id="3M">
                                            <a
                                                href="#navitem"
                                                className={chartType === "Weighted Returns" ? "nav-link active" : "nav-link"}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setChartType("Weighted Returns");
                                                }}
                                            >
                                                Weighted Returns
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="card-inner">

                                {
                                    chartType == "Account Returns" && roiPerc.length > 0
                                        ? <Line data={data} options={options} />
                                        : null
                                }

                                {
                                    chartType == "Weighted Returns" && roiPercweighted.length > 0
                                        ? <Line data={dataweighted} options={options} />
                                        : null
                                }




                            </div>


                        </div>

                    ) : (null)
            }




        </React.Fragment>
        </ErrorBoundary>
    );

};
export default FTTChartOverview;

