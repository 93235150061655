import React, {useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import Icon from '../../../../assets/components/icon/Icon';
import {AudienceLineChart} from "../balance_equity_chart/BalanceChart";
import {CardTitle} from "reactstrap";
import axios from "../../../../api/axios";
import {Doughnut} from "react-chartjs-2";

import {
    PreviewAltCard,
} from "../../../../assets/components/Component";


const MARGIN = '/api/rtm/portfolio_margin/';
const PortfolioMargin = () => {
    const [loaded, setLoaded] = useState(false);
    const [data, setData] = useState({});
    const [data2, setData2] = useState({
        "margin": 0.0,
        "portfolio_id": 1,
        "marginfree": 0.0,
        "marginlevel": 0.00,
        "marginlevelperc": 0.00,
    });
    const params = useParams()
    useEffect(() => {
        async function fetchPortfolioMargin() {
            try {
                var token = "";
                if (typeof window !== 'undefined') {
                    token = localStorage.getItem('Token');
                }
                const response = await axios.get(MARGIN + params.portId, {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Authorization': ['Bearer ', token].join('')
                    }
                });
                setData2(await response?.data);

                setData({
                    labels: ["free Margin", "used Margin"], dataUnit: "BTC", legend: true, datasets: [{
                        borderColor: "#fff",
                        backgroundColor: ["rgba(156, 171, 255, 0.8)", "rgba(244, 170, 164, 0.8)", "rgba(143, 234, 197, 0.8)"],
                        data: [await response?.data?.marginfree.toFixed(2), await response?.data?.margin.toFixed(2)],
                    },],
                })


            } catch (e) {
                console.log(e);
            }
        }

        fetchPortfolioMargin();
        setLoaded(true);
    }, []);

    function currencyFormat(amount) {
        return (amount).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    }

    if (loaded == true) {
        return (<React.Fragment>
            <div className="card-title-group align-start pb-3 g-2">
                <div className="card-title card-title-sm"><h6 className="title">Margin Utilization</h6>
                    <p>How
                        much Margin is used by the Portfolio.</p></div>
            </div>
                <div className="card-inner">

                    <div className="card card-bordered h-100">
                        <div className="card-inner">


                                <div className="analytic-data-group analytic-au-group g-3" style={{textAlign: 'center'}}>
                                    <div className="analytic-data analytic-au-data">
                                        <div className="title">Utilization</div>
                                        <div className="amount">{(data2.marginlevel *100).toFixed(2)} %</div>

                                    </div>
                                    <div className="analytic-data analytic-au-data">
                                        <div className="title">Margin Level</div>
                                        <div className="amount">{(data2.marginlevelperc *100).toFixed(2)} %</div>

                                    </div>
                                    <div className="analytic-data analytic-au-data">
                                        <div className="title">Free</div>
                                        <div className="amount">{currencyFormat(data2.marginfree)} EUR</div>

                                    </div>
                                    <div className="analytic-data analytic-au-data">
                                        <div className="title">Used</div>
                                        <div className="amount">{currencyFormat(data2.margin)} EUR</div>
                                    </div>
                                </div>
                                <div className="analytic-au-ck">
                                    <Doughnut
                                        data={data}
                                        options={{
                                            legend: {
                                                display: true, position: "bottom",
                                            }, rotation: 1, cutoutPercentage: 40, maintainAspectRatio: false, tooltips: {
                                                enabled: true,
                                                backgroundColor: "#eff6ff",
                                                titleFontSize: 13,
                                                titleFontColor: "#6783b8",
                                                titleMarginBottom: 6,
                                                bodyFontColor: "#9eaecf",
                                                bodyFontSize: 12,
                                                bodySpacing: 4,
                                                yPadding: 10,
                                                xPadding: 10,
                                                footerMarginTop: 0,
                                                displayColors: false,
                                            },
                                        }}
                                    />

                                </div>



                        </div>
                    </div>
                </div>
            </React.Fragment>)
    }

};
export default PortfolioMargin;