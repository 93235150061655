import Unauthorized from './components/Unauthorized';
import RequireAuth from './components/RequireAuth';
import {Routes, Route} from 'react-router-dom';
import Missing from './components/Missing';
import Login from './components/Login/Login';
import Layout from "./components/Layout";
import Home from './components/RTM/Home'
import Admin from "./components/Admin/admin";

import Portfolio_List from "./pages/rtm/Portfolios";
import SpecificAccount from './pages/rtm/SpecificAccount';
import SpecificPortfolio from "./pages/rtm/SpecificPortfolio";
import Account_List from "./pages/rtm/Accounts";
import IB_Management from "./pages/ib_management/ib_management";
import TraderPerformance from "./pages/Trader_performance/TradePerformance";
import SpecificTradingSystem from "./pages/Trader_performance/Pages/SpecificTradingSystem";

const ROLES = {
    'User': 2001,
    'Admin': 5150,
    'superAdmin': 1337
}

function App() {
    return (
        <Routes>
            <Route path="/" element={<Layout/>}>

                {/* public routes */}
                <Route path="login" element={<Login/>}/>
                <Route path="unauthorized" element={<Unauthorized/>}/>

                {/* we want to protect these routes */}
                <Route element={<RequireAuth allowedRoles={[ROLES.Admin, ROLES.User]}/>}>
                    <Route path="/" element={<Home/>}>
                        <Route path="portfolios">
                            <Route index element={<Portfolio_List/>}/>
                            <Route path=":portId" element={<SpecificPortfolio/>}/>
                        </Route>
                        <Route path="accounts">
                            <Route index element={<Account_List/>}/>
                            <Route path=":userId" element={<SpecificAccount/>}/>
                        </Route>
                    </Route>
                </Route>

                <Route element={<RequireAuth allowedRoles={[ROLES.Admin]}/>}>
                    <Route path="/" element={<Home/>}>
                        <Route path="admin" element={<Admin/>}/>
                        <Route path="ib-management" element={<IB_Management/>}/>

                    </Route>
                </Route>
                <Route element={<RequireAuth allowedRoles={[ROLES.Admin]}/>}>
                    <Route path="/" element={<Home/>}>
                        <Route path="trader-performance">
                            <Route index element={<TraderPerformance/>}/>
                            <Route path=":tradingsystem" element={<SpecificTradingSystem/>}/>
                        </Route>
                    </Route>
                </Route>
                {/* catch all */}
                <Route path="*" element={<Missing/>}/>
            </Route>
        </Routes>
    );
}

export default App;
