import axios from "../../../../api/axios";
import { Alert, Table } from "reactstrap";
import Icon from "../../../../assets/components/icon/Icon";
import { useParams } from "react-router-dom";
import moment from "moment";
import React, {useState, useEffect, Component} from 'react'

import FTTChartOverview from "./ftt_portfolio_return_chart_overview";

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    componentDidCatch(error, info) {
        this.setState({ hasError: true });
        // You can also log the error to a logging service
        console.error(error);
    }

    render() {
        if (this.state.hasError) {
            // You can render a custom error message here
            return <div>Something went wrong.</div>;
        }
        return this.props.children;
    }
}

const FTT_RETURN = '/api/rtm/ftt_return_portfolio/';

const Ftt_portfolio_return_table = () => {
    const params = useParams()
    const [activeAlerts, setactiveAlerts] = useState([]);
    const [AllowedSymbols, setAllowedSymbols] = useState([]);

    const [AllowedSymbolsorg, setAllowedSymbolsorg] = useState([]);

    const [headline, setHeadline] = useState("Investor Statistic - ITD");
    const [fttusers, setFttUsers] = useState(JSON.parse(localStorage.getItem('FTTUser')))
    const [roles, setRoles] = useState(localStorage.getItem('Roles'));
    const [datatime, setdatatime] = useState();
    const [balancetotal, setbalancetotal] = useState(0);
    const [investtotal, setinvesttotal] = useState(0);
    const [returntotal, setreturntotal] = useState(0);
    const [Fttusersdropdown, setfttusersdropdown] = useState();
    const [selectedUserChart, setselectedUserChart] = useState();
    const [accountlistftt, setaccountlistftt] = useState([]);
    const [loaded, setloaded] = useState(false);



    useEffect(() => {
        async function fetchFTTReturnAccount() {
            try {
                var token = "";
                if (typeof window !== 'undefined') {
                    token = localStorage.getItem('Token');
                }
                const response = await axios.get(FTT_RETURN + params.portId, {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Authorization': ['Bearer ', token].join('')
                    }
                });

                let balancetmp = 0;
                let investtmp = 0;
                if (fttusers.length > 0) {
                    let newData = [];

                    for (let value in fttusers) {

                        let filteredData = await response?.data.filter(
                            item =>
                                item.client_id.toString().toLowerCase().includes(fttusers[value])
                        );

                        for (let idx in filteredData) {
                            filteredData[idx]["share"] = 0;
                            newData.push(filteredData[idx]);
                            balancetmp = balancetmp + filteredData[idx]["balance"];
                            investtmp = investtmp + filteredData[idx]["invested_amount"];
                        };


                    }
                    setbalancetotal(balancetmp);
                    setinvesttotal(investtmp);
                    setreturntotal(((balancetmp / investtmp * 100) - 100).toFixed(2))
                    setAllowedSymbols(newData);
                    setAllowedSymbolsorg(newData);
                    setHeadline("Your Personal Statistic");
                }
                else {
                    setAllowedSymbols(await response?.data);
                    setAllowedSymbolsorg(await response?.data);
                    for (let idx in await response?.data) {
                        balancetmp = balancetmp + await response?.data[idx]["balance"];
                        investtmp = investtmp + await response?.data[idx]["invested_amount"];
                    };
                    setbalancetotal(balancetmp);
                    setinvesttotal(investtmp);
                }
                setdatatime(moment(await response?.data[0]["run_date"]).format('LLL'));
                if (fttusers.length == 1)
                {
                    setselectedUserChart(fttusers[0]);
                    let tmp = await response?.data.filter(item => item.client_id.toString().toLowerCase().includes(fttusers[0]));

                    let accountlist_tmp = [];
                    for (var key in tmp) {
                        accountlist_tmp.push(tmp[key].ftt_accountid);
                    }
                    setaccountlistftt(accountlist_tmp);
                    console.log(accountlist_tmp);
                }
                setloaded(true);


            }
            catch (e) {
                console.log(e);
            }
        }


        fetchFTTReturnAccount();

    }, [])

    useEffect(() => {
        let temp_dropdownusers = [];
        for (let tmp in AllowedSymbolsorg) {
            let clientid = AllowedSymbolsorg[tmp].client_id;

            let newname = "";
            if (AllowedSymbolsorg[tmp].account_type == "Private") {
                newname = "Priv - " + AllowedSymbolsorg[tmp].name;
            }
            else {
                newname = "Corp - " + AllowedSymbolsorg[tmp].company;
            }


            temp_dropdownusers.push({
                "client": clientid,
                "name": AllowedSymbolsorg[tmp].name,
                "type": AllowedSymbolsorg[tmp].account_type,
                "company": AllowedSymbolsorg[tmp].company,
                "newname": newname
            })
        }
        const ids = temp_dropdownusers.map(o => o.client)
        const filtered = temp_dropdownusers.filter(({ client }, index) => !ids.includes(client, index + 1))
        setfttusersdropdown(filtered);

    }, [AllowedSymbolsorg])


    function currencyFormat(amount) {
        return (amount).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    }
    const renderallowedSymbolHeader = () => {
        let headerElement = ['Account', 'Type', 'Name', 'Company', 'Account Balance', 'Invested Amount', 'Share %', 'Return %']

        return headerElement.map((key, index) => {
            return <th key={index}>{key.toUpperCase()}</th>
        })
    }
    const renderallowedSymbolBody = () => {
        return AllowedSymbols && AllowedSymbols.map(({ mt4_account, mt4_accountname, client_id, account_type, name, company, balance, invested_amount, roi_perc, share }) => {
            return (
                <tr key={"id" + Math.random().toString(16).slice(2)}>
                    <td>{mt4_account + " " + mt4_accountname}</td>
                    <td>{account_type}</td>
                    <td>{name}</td>
                    <td>{company}</td>
                    <td>{currencyFormat(balance)}</td>
                    <td>{currencyFormat(invested_amount)}</td>
                    <td>{(balance/balancetotal*100).toFixed(2)}</td>
                    <td>{roi_perc.toFixed(2)}</td>
                </tr>
            )
        })
    }

    const changeAlertType = (e) => {
        setselectedUserChart(e.target.value);
        if (e.target.value == 0) {
            setAllowedSymbols(AllowedSymbolsorg);
            let balancetmp = 0;
            let investtmp = 0;

            for (let idx in AllowedSymbolsorg) {
                balancetmp = balancetmp + AllowedSymbolsorg[idx]["balance"];
                investtmp = investtmp + AllowedSymbolsorg[idx]["invested_amount"];
            };
            setbalancetotal(balancetmp);
            setinvesttotal(investtmp);
            setreturntotal(((balancetmp / investtmp * 100) - 100).toFixed(2));
        }
        else {
            let tmp = AllowedSymbolsorg.filter(item => item.client_id.toString().toLowerCase().includes(e.target.value));
            setAllowedSymbols(tmp);
            let balancetmp = 0;
            let investtmp = 0;

            for (let idx in tmp) {
                balancetmp = balancetmp + tmp[idx]["balance"];
                investtmp = investtmp + tmp[idx]["invested_amount"];
            };




            setbalancetotal(balancetmp);
            setinvesttotal(investtmp);
            setreturntotal(((balancetmp / investtmp * 100) - 100).toFixed(2));

            let accountlist_tmp = [];
            for (var key in tmp) {
                accountlist_tmp.push(tmp[key].ftt_accountid);
            }
            setaccountlistftt(accountlist_tmp);
        }


    }

    if (loaded) {
        return (
            <ErrorBoundary>
            <React.Fragment>
                <div className="card-inner">
                    <div className="card-title-group">
                        <div className="card-title card-title-sm">
                            <h6 className="title">{headline}</h6>
                        </div>
                        <div className="card-tools">
                            <ul className="card-tools-nav">
                                <li >
                                    <span>Last updated: {datatime} (updated Daily)</span><br></br>
                                    <a href="https://live.magmarkets.com/customer/reports/investor_statement">Click here for Investor Statement Report</a>
                                </li>

                            </ul>
                        </div>
                    </div>
                    <div className="mt-4">
                        { fttusers.length == 1? (
                            <div></div>
                        ) : (
                            <select id="selectuserfield" onChange={(e) => changeAlertType(e)}>
                                <option key="all" value={0}>All</option>
                                {Fttusersdropdown.map((key) => {
                                    return <option key={key.client} value={key.client}>{key.newname}</option>
                                })}
                            </select>
                        )}

                    </div>
                </div>

                <div className="card-inner">
                    <Table responsive className="table" id='allowed_symbols'>
                        <thead>
                            <tr key="trheader">{renderallowedSymbolHeader()}</tr>
                        </thead>
                        <tbody>
                            {renderallowedSymbolBody()}
                        </tbody>
                        <tfoot>
                            <tr key="trfooter">
                                <td>Total</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>{currencyFormat(balancetotal)}</td>
                                <td>{currencyFormat(investtotal)}</td>
                                <td></td>
                                <td>{returntotal}</td>
                            </tr>
                        </tfoot>
                    </Table>

                </div>
                <FTTChartOverview fttuser={selectedUserChart} accountlist={accountlistftt}></FTTChartOverview>
            </React.Fragment>
            </ErrorBoundary>
        )
    }
}


export default Ftt_portfolio_return_table