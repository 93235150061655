import React, { useState, useEffect } from "react";
import {
    Block,
    BlockDes,
    BlockHead,
    BlockHeadContent,
    BlockBetween,
    BlockTitle,
    PreviewAltCard,
    PreviewCard,
    Icon,
    Button,
    Row,
    Col,
    TooltipComponent
} from "../../../../assets/components/Component";
import { findUpper } from "../../../../utils/Utils";
import UserAvatar from '../../../../assets/components/user/UserAvatar';
import { Link, useParams } from "react-router-dom";
import { Card, UncontrolledDropdown, DropdownMenu, DropdownItem, DropdownToggle } from "reactstrap";
import axios from "../../../../api/axios";

const PORTFOLIO_MEMBERS = '/api/rtm/portfolio_constitutes/';
const FTT_RETURN = '/api/rtm/ftt_return_portfolio/';

const Portfolio_Constitues_Cards = () => {

    const [loaded, setLoaded] = useState(false);
    const [cardData, setcardData] = useState([]);
    const [fttusers, setFttUsers] = useState(JSON.parse(localStorage.getItem('FTTUser')))
    const [fttdata, setfttdata] = useState([]);
    const [fttdataroi, setfttdataroi] = useState([]);
    const [fttdatabalance, setfttdatabalance] = useState(0);
    const params = useParams()
    useEffect(() => {
        async function fetchPortfolioConsts() {
            try {
                var token = "";
                if (typeof window !== 'undefined') {
                    token = localStorage.getItem('Token');
                }
                const response = await axios.get(PORTFOLIO_MEMBERS + params.portId, {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Authorization': ['Bearer ', token].join('')
                    }
                });

                setcardData(await response?.data);

                if (fttusers.length == 1)
                {
                    const responseftt = await  axios.get(FTT_RETURN + params.portId, {
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded',
                            'Authorization': ['Bearer ', token].join('')
                        }
                    });

                    let filteredData = await responseftt?.data.filter(
                        item =>
                            item.client_id.toString().toLowerCase().includes(fttusers[0])
                    );

                    let dictionary = Object.assign({}, ...await responseftt?.data.filter(item => item.client_id.toString().toLowerCase().includes(fttusers[0])).map((x) => ({[x.mt4_account]: x.balance})));

                    setfttdata(dictionary);
                    setfttdataroi(Object.assign({}, ...await responseftt?.data.filter(item => item.client_id.toString().toLowerCase().includes(fttusers[0])).map((x) => ({[x.mt4_account]: x.roi_perc}))));





                    let tmpbalance = 0;
                    for (let idx in filteredData) {
                        tmpbalance = tmpbalance + filteredData[idx]["balance"];
                    };
                    console.log(tmpbalance);
                    setfttdatabalance(tmpbalance);

                    //setfttdata(await responseftt?.data.filter(item => item.client_id.toString().toLowerCase().includes(fttusers[0])));
                    //console.log(filteredData.filter(item => item.mt4_account.toString().toLowerCase().includes(65719))[0]["balance"]);

                    if (await responseftt?.status == 200)
                    {
                        setLoaded(true);
                    }
                }

                if (await response?.status == 200)
                {
                    setLoaded(true);
                }

            } catch (e) {
                console.log(e);
            }
        }

        fetchPortfolioConsts();

    }, []);

    function currencyFormat(amount) {
        return (amount).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    }

    if (loaded == true) {
        return (
            <React.Fragment>
                <div className="card-title-group pb-3 g-2">
                    <div className="card-title card-title-sm">
                        <h6 className="title">Constituents</h6>
                        <p>In this section you can find all portfolio members and analyze the individual performance by
                            clicking on one of the accounts.</p>
                    </div>
                </div>
                <div className="card-inner">
                    <div className="row">
                        {cardData.map((item) => {
                            return (
                                <Col key={item.trading_account_id} md="6" lg="4">
                                    <PreviewAltCard className='mb-4'>
                                        <div className="team">
                                            <div className="user-card user-card-s2">
                                                <UserAvatar theme="purple" className="md" text={findUpper(item.name)}>
                                                    <div className="status dot dot-lg dot-success"></div>
                                                </UserAvatar>
                                                <div className="user-info">
                                                    <h6>{item.name}</h6>
                                                    <span className="sub-text">#{item.trading_account_id}</span>
                                                </div>
                                            </div>

                                            <div className='user-invests-row'>

                                                <div className="item">
                                                    <div className="inner-item">
                                                        <span>Balance</span>
                                                        {fttusers.length == 1 ? (

                                                            <span>{currencyFormat(fttdata[item.trading_account_id])}</span>
                                                        ):(
                                                            <span>{currencyFormat(item.trading_account_balance)}</span>
                                                        )}

                                                    </div>

                                                    <div className="inner-item">
                                                        <span>Performed</span>
                                                        {fttusers.length == 1 ? (
                                                            <span>{(fttdataroi[item.trading_account_id]).toFixed(2)}%</span>
                                                        ):(
                                                        <span>{(item.account_return_total * 100).toFixed(2)}%</span>
                                                        )}
                                                    </div>
                                                </div>


                                                <div className="item">
                                                    <div className="inner-item">
                                                        <span>Portfolio Share</span>
                                                        {fttusers.length == 1 ? (
                                                            <span>{(fttdata[item.trading_account_id] / fttdatabalance *100).toFixed(2)}%</span>
                                                        ):(
                                                            <span>{(item.trading_account_balance / item.portfolio_balance * 100).toFixed(2)}%</span>
                                                        )}

                                                    </div>
                                                    <div className="inner-item">
                                                        <span>Trades</span>
                                                        <span>{item.total_trades_account}</span>
                                                    </div>
                                                </div>


                                                    {fttusers.length == 1 ? (
                                                            <div className="item">
                                                            </div>
                                                    ):(
                                                        <div className="item">
                                                            <div className="inner-item">
                                                                <span>Open Lot</span>
                                                                <span>{(item.open_lot).toFixed(2)}</span>
                                                            </div>

                                                            <div className="inner-item">
                                                                <span>PnL</span>
                                                                <span>{currencyFormat(item.open_pnl)}</span>
                                                            </div>
                                                        </div>
                                                    )}
                                                {fttusers.length == 1 ? (
                                                    <div className="item">
                                                    </div>
                                                ):(
                                                    <div className="item">
                                                        <div className="inner-item">
                                                            <span>Open Volume</span>
                                                            <span>{currencyFormat(item.volume_usd)} USD</span>
                                                        </div>
                                                        <div className="inner-item">
                                                            <span></span>
                                                            <span></span>
                                                        </div>

                                                    </div>
                                                )}


                                            </div>
                                            <div className="team-view">
                                                <Link
                                                    to={`${process.env.PUBLIC_URL}/accounts/` + item.trading_account_id}
                                                    className="btn btn-round btn-outline-light"
                                                >
                                                    <span>View Account</span>
                                                </Link>
                                            </div>
                                        </div>
                                    </PreviewAltCard>
                                </Col>)
                        })}
                    </div>
                </div>



            </React.Fragment>
        )
    }
}
export default Portfolio_Constitues_Cards;