import React from "react";
import {Link, useNavigate }  from "react-router-dom";

import UserAvatar from '../../../../assets/components/user/UserAvatar';
import Icon from '../../../../assets/components/icon/Icon';
import { DropdownItem, DropdownMenu, DropdownToggle, Progress, UncontrolledDropdown } from "reactstrap";
import { DataTableHead, DataTableItem, DataTableRow } from "../../../../assets/components/table/DataTable";
import { findUpper } from "../../../../utils/Utils";

import {useEffect, useState} from "react";
import axios from "../../../../api/axios";
import moment from 'moment';
import {Col} from "../../../../assets/components/grid/Grid";
import {PreviewAltCard} from "../../../../assets/components/preview/Preview";

const ACCOUNTS_URL = '/api/rtm/allowed_accounts';

const AccountsTable = () => {
    const [accounts, setAccounts] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const navigate = useNavigate();
    const [roles, setRoles] = useState([]);


    function currencyFormat(amount){
        return (amount).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    }

    function clicked() {
        console.log("clicked");
    }

    function changeTimeZone(date, timeZone) {
        if (typeof date === 'string') {
            return new Date(
                new Date(date).toLocaleString('en-US', {
                    timeZone,
                }),
            );
        }
    }

    useEffect(() => {
        async function fetchData() {
            try {
                var token = "";
                if (typeof window !== 'undefined') {
                    token = localStorage.getItem('Token');
                    setRoles(localStorage.getItem('Roles'));
                }
                const response = await axios.get(ACCOUNTS_URL, {headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Authorization': ['Bearer ', token].join('')
                    }});

                setAccounts(await response?.data);
                setLoading(false);

                var accountsTMP = {};

                for(var i=0; i<await response?.data.length; i++)
                {
                    accountsTMP[response?.data[i]["account_id"]] = response?.data[i]["name"];
                }
                localStorage.setItem("accounts", JSON.stringify(accountsTMP));

            }
            catch (e)
            {
                console.log(e);
            }

        }
        fetchData();

    }, []);

    if (isLoading == false)
    {


    return (
        <React.Fragment>
            {" "}
            <div className="card-inner border-bottom">
                <div className="card-title-group">
                    <div className="card-title">
                        <h6 className="title">Accounts</h6>
                    </div>
                </div>
            </div>
            <div className="nk-tb-list">
                <DataTableHead>
                    <DataTableRow>
                        <span>Account ID</span>{" "}
                    </DataTableRow>
                    <DataTableRow size="lg">
                        <span>Name</span>
                    </DataTableRow>
                    <DataTableRow size="lg">
                        <span>Balance</span>
                    </DataTableRow>
                    {roles.includes(5150) ? (
                        <DataTableRow>
                            <span>Equity</span>
                        </DataTableRow>


                    ): (
                        <div></div>
                    )}
                    {roles.includes(5150) ? (
                        <DataTableRow>
                            <span>PnL</span>
                        </DataTableRow>


                    ): (
                        <div></div>
                    )}
                    <DataTableRow>
                        <span>last update</span>
                    </DataTableRow>
                    <DataTableRow>
                        <span>status</span>
                    </DataTableRow>
                    <DataTableRow size="sm">
                        <span>&nbsp;</span>
                    </DataTableRow>

                </DataTableHead>
                {accounts.map((item) => {
                    return (
                        <DataTableItem key={item.account_id} >
                            <DataTableRow>
                                <div className="align-center">

                                    <UserAvatar size="sm" theme="light" text={accounts.indexOf(item)}></UserAvatar>
                                    <span className="tb-sub ml-2">
                     <span className="d-none d-md-inline" >{item.account_id}</span>

                  </span>
                                </div>
                            </DataTableRow>
                            <DataTableRow size="sm">
                                <div className="user-card" >
                                    <UserAvatar size="sm" theme="pink-dim" text={findUpper(item.name)} />
                                    <div className="user-name">
                                        <span className="tb-lead">{item.name}</span>
                                    </div>
                                </div>
                            </DataTableRow>
                            <DataTableRow size="lg">
                                <span className="tb-sub tb-amount">
                                    {currencyFormat(item.balance)} <span>EUR</span>
                                </span>
                            </DataTableRow>

                            {roles.includes(5150) ? (
                                <DataTableRow>
                                <span className="tb-sub tb-amount">
                                  {currencyFormat(item.equity)} <span>EUR</span>
                                </span>
                                </DataTableRow>


                            ): (
                                <div></div>
                            )}


                            {roles.includes(5150) ? (
                                <DataTableRow>

                                    {item.pln >= 0 ? (
                                        <span className="tb-sub tb-amount text-success">
                                  {currencyFormat(item.pln)} <span>EUR</span>
                                </span>
                                    ) : (
                                        <span className="tb-sub tb-amount text-danger">
                                  {currencyFormat(item.pln)} <span>EUR</span>
                                </span>
                                    )}



                                </DataTableRow>


                            ): (
                                <div></div>
                            )}



                            <DataTableRow size="lg">
                                <span className="d-none d-md-inline">{moment(changeTimeZone(item.last_update, 'Europe/Berlin')).fromNow(true)}</span>
                            </DataTableRow>
                            <DataTableRow>
                                {item.status === true ? (
                                    <span className="tb-sub text-success">Active</span>
                                ) : (
                                    <span className="tb-sub text-danger">Deactive</span>
                                )}

                            </DataTableRow>
                            <DataTableRow className="nk-tb-col-action">
                                <UncontrolledDropdown>
                                    <DropdownToggle tag="a" className="text-soft dropdown-toggle btn btn-sm btn-icon btn-trigger">
                                        <Icon name="chevron-right"></Icon>
                                    </DropdownToggle>
                                    <DropdownMenu right className="dropdown-menu-xs">
                                        <ul className="link-list-plain">
                                            <li>
                                                <DropdownItem
                                                    tag="a"
                                                    href={`${process.env.PUBLIC_URL + "/accounts/" + item.account_id}`}
                                                    onClick={(ev) => {
                                                        ev.preventDefault();
                                                        navigate("/accounts/" + item.account_id);

                                                    }}
                                                >
                                                    View
                                                </DropdownItem>
                                            </li>

                                        </ul>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </DataTableRow>

                        </DataTableItem>
                    );
                })}
            </div>
        </React.Fragment>
    );
    }
};

export default AccountsTable;
