import { useNavigate, Link } from "react-router-dom";
import { useContext,useEffect, useState, useLayoutEffect } from "react";
import AuthContext from "../../context/AuthProvider";
import brandlog from '../../assets/images/mag_brand_logo.png'
import classNames from "classnames";
import Sidebar from '../sidebar/Sidebar'
import Sidebar_accounts from "./sidebar_accounts";
import Header from '../header/Header';
import Content from "../content/Content";
import Account_List from "../../pages/rtm/Accounts";
import { Route, Routes } from "react-router-dom";
import Portfolio_List from "../../pages/rtm/Portfolios";
import SpecificAccount from '../../pages/rtm/SpecificAccount';
import SpecificPortfolio from "../../pages/rtm/SpecificPortfolio";

import IB_Management from "../../pages/ib_management/ib_management";
import TraderPerformance from "../../pages/Trader_performance/TradePerformance";
import SpecificTradingSystem from "../../pages/Trader_performance/Pages/SpecificTradingSystem";

const Home = () => {
    const { setAuth } = useContext(AuthContext);
    const navigate = useNavigate();
    const [mobileView, setMobileView] = useState();
    const [visibility, setVisibility] = useState(false);
    const [themeState] = useState({
        main: "dark",
        sidebar: "dark",
        header: "dark",
        skin: "dark",
    });

    useEffect(() => {
        viewChange();
    }, []);

    useLayoutEffect(() => {
        if (visibility) {
            document.body.style.overflow = "hidden";
            document.body.style.height = "100%";
        }
        if (!visibility) {
            document.body.style.overflow = "auto";
            document.body.style.height = "auto";
        }
    }, [visibility]);

    const toggleSidebar = (e) => {
        e.preventDefault();
        if (visibility === false) {
            setVisibility(true);
        } else {
            setVisibility(false);
        }
    };

    const logout = async () => {
        // if used in more components, this should be in context
        // axios to /logout endpoint
        if (typeof window !== 'undefined') {
            localStorage.removeItem('Token');
            localStorage.removeItem('Roles');
            localStorage.removeItem('FTTUser');
            localStorage.removeItem('auth');
        }
        console.log("logout");
        setAuth({});
        navigate('/');
    }

    // function to change the design view under 1200 px
    const viewChange = () => {
        if (window.innerWidth < 1200) {
            setMobileView(true);
        } else {
            setMobileView(false);
            setVisibility(false);
        }
    };
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);

    const sidebarClass = classNames({
        "nk-sidebar-mobile": mobileView,
        "nk-sidebar-active": visibility && mobileView,
    });

    return (
        <div className="nk-main">
            <Sidebar
                sidebarToggle={toggleSidebar}
                fixed
                mobileView={mobileView}
                theme={themeState.sidebar}
                className={sidebarClass}
            />
            {visibility && mobileView && <div className="nk-sidebar-overlay" onClick={toggleSidebar}></div>}

            <div className="nk-wrap">
                <Header sidebarToggle={toggleSidebar} setVisibility={setVisibility} fixed theme={themeState.header} />

                    <Routes >

                        <Route exact path=""
                               element={<Account_List />}>
                        </Route>
                        <Route path="accounts">
                            <Route index element={<Account_List />} />
                            <Route path=":userId" element={<SpecificAccount />} />
                        </Route>

                        <Route path="portfolios">
                            <Route index element={<Portfolio_List />} />
                            <Route path=":portId" element={<SpecificPortfolio />} />
                        </Route>

                        <Route exact path="ib-management"
                               element={<IB_Management />}>
                        </Route>

                        <Route path="trader-performance">
                            <Route index element={<TraderPerformance />} />
                            <Route path=":tradingsystem" element={<SpecificTradingSystem />} />
                        </Route>
                    </Routes>
            </div>
        </div>
    )
}

export default Home