import React, { useState, useEffect } from 'react';
import axios from "../../../../api/axios";
import {Alert} from "reactstrap";
import Icon from "../../../../assets/components/icon/Icon";
import DatePicker from "react-datepicker";
import moment from 'moment';



const ReturnCalculator_Table = ({ data}) => {
    const [portfolionames, setportfolioNames] = useState(JSON.parse(localStorage.getItem("portfolios")));
    const [accountnames, setaccountnames] = useState(JSON.parse(localStorage.getItem("accounts")));
    useEffect(() => {
        let x = data.sort((a, b) => (a.calc_id < b.calc_id ? 1 : -1));
        data = x;
    }, []);

    const renderActiveAlertsHeader = ()=> {
        let headerElement = ['#', 'Account', 'Start Balance', 'PF', 'End Balance Gross', 'End Balance Net', 'Date From', 'Date To', 'Gross %', 'Net %']

        return headerElement.map((key, index) => {
            return <th key={index}>{key.toUpperCase()}</th>
        })
    }

    const renderActiveAlertsBody = () => {
        return data && data.map(({ calc_id, portfolio_id, trading_account_id, start_balance, end_balance_gross, end_balance_net, start_date, end_date, return_gross, return_net, pf_settings}) => {
            return (
                <tr key={calc_id}>
                    <td>{calc_id}</td>
                    {portfolio_id == 0 ? (
                            <td>{accountnames[trading_account_id]}</td>
                        )
                    :(
                            <td>{portfolionames[portfolio_id]}</td>
                        )}

                    <td>{start_balance}</td>
                    <td>{pf_settings}</td>
                    <td>{end_balance_gross.toFixed(2)}</td>
                    <td>{end_balance_net.toFixed(2)}</td>
                    <td>{start_date}</td>
                    <td>{end_date}</td>
                    <td>{(return_gross*100).toFixed(2)}</td>
                    <td>{(return_net*100).toFixed(2)}</td>
                </tr>
            )
        })
    }

    return (

        <div className="card-inner">
            <table className="table">
                <thead>
                <tr>{renderActiveAlertsHeader()}</tr>
                </thead>
                <tbody>
                {renderActiveAlertsBody()}
                </tbody>
            </table>
        </div>
    )
}

export default ReturnCalculator_Table