import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { AuthProvider } from './context/AuthProvider';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import "./assets/scss/dashlite.scss";
import "./assets/scss/main.scss";


const rootElement = document.getElementById("root");
const root = ReactDOM.createRoot(rootElement);

root.render(

    <BrowserRouter>
        <AuthProvider>
            <Routes>
                <Route path="/*" element={<App />} />
            </Routes>
        </AuthProvider>
    </BrowserRouter>


);

