import React, {useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import Icon from '../../../../assets/components/icon/Icon';
import {AudienceLineChart} from "../balance_equity_chart/BalanceChart";
import {CardTitle} from "reactstrap";
import axios from "../../../../api/axios";
import {Doughnut, Bar} from "react-chartjs-2";

import {
    PreviewAltCard,
} from "../../../../assets/components/Component";


const DRAWDOWN = '/api/rtm/portfolio_drawdown/';
const PortfolioDrawdown = () => {
    const [loaded, setLoaded] = useState(false);
    const [data, setData] = useState([
        {
            "portfolio_number": 1,
            "date_formated": "2022-08-04",
            "max_dd": -0.0,
            "last_dd": 0.0,
            "max_dd_overal": 0.0,
        },
        {
            "portfolio_number": 1,
            "date_formated": "2022-08-05",
            "max_dd": -0.0,
            "last_dd": 0.0,
            "max_dd_overal": 0.0,
        },
        {
            "portfolio_number": 1,
            "date_formated": "2022-08-06",
            "max_dd": -0.0,
            "last_dd": 0.0,
            "max_dd_overal": 0.0,
        },
        {
            "portfolio_number": 1,
            "date_formated": "2022-08-08",
            "max_dd": 0.0,
            "last_dd": 0.0,
            "max_dd_overal": 0.0,
        }
    ]);
    const [data2, setData2] = useState({
        labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
        stacked: true,
        dataUnit: "USD",
        datasets: [

            {
                label: "Drawdown %",
                backgroundColor: "#f4aaa4",
                data: [10, 90, 110, 80, 125, 55, 95, 75, 90, 110, 80, 125],
            },
        ],
    });
    const params = useParams()
    useEffect(() => {
        async function fetchPortfolioDrawdown() {
            try {
                var token = "";
                if (typeof window !== 'undefined') {
                    token = localStorage.getItem('Token');
                }
                const response = await axios.get(DRAWDOWN + params.portId, {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Authorization': ['Bearer ', token].join('')
                    }
                });
                setData(await response?.data);


                setData2({
                    labels: await response?.data.map(function(o) { return o.date_formated }),
                    stacked: true,
                    dataUnit: "USD",
                    datasets: [

                        {
                            label: "Drawdown %",
                            backgroundColor: "#f4aaa4",
                            data: await response?.data.map(function(o) { return (((o.max_dd)*-1)*100).toFixed(2) }),
                        },
                    ],
                })




            } catch (e) {
                console.log(e);
            }
        }

        fetchPortfolioDrawdown();
        setLoaded(true);
    }, []);

    function currencyFormat(amount) {
        return (amount).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    }

    if (loaded == true) {
        return (<React.Fragment>
            <div className="card-title-group align-start pb-3 g-2">
                <div className="card-title card-title-sm"><h6 className="title">Drawdown</h6>
                    <p>Track the Drawdown (percentage) of the Portfolio. Drawdown is calculated based on open Positions relative to the Portfolio Balance</p></div>
            </div>
            <div className="card-inner">

                <div className="card card-bordered h-100">
                    <div className="card-inner">


                        <div className="analytic-data-group analytic-au-group g-3" style={{textAlign: 'center'}}>
                            <div className="analytic-data analytic-au-data">
                                <div className="title">Curent</div>
                                <div className="amount">{(((Object.values(data).pop().last_dd)*-1)*100).toFixed(2)} %</div>

                            </div>
                            <div className="analytic-data analytic-au-data">
                                <div className="title"></div>
                                <div className="amount"></div>

                            </div>
                            <div className="analytic-data analytic-au-data">
                                <div className="title">Max</div>
                                <div className="amount">{(((Math.min.apply(Math, data.map(function(o) { return o.max_dd_overal; })))*-1)*100).toFixed(2)} %</div>
                            </div>
                        </div>
                        <div className="analytic-au-ck">
                            <Bar
                                data={data2}
                                options={{
                                    legend: {
                                        display: false,
                                        labels: {
                                            boxWidth: 30,
                                            padding: 20,
                                            fontColor: "#6783b8",
                                        },
                                    },
                                    maintainAspectRatio: false,
                                    tooltips: {
                                        enabled: true,
                                        backgroundColor: "#eff6ff",
                                        titleFontSize: 13,
                                        titleFontColor: "#6783b8",
                                        titleMarginBottom: 6,
                                        bodyFontColor: "#9eaecf",
                                        bodyFontSize: 12,
                                        bodySpacing: 4,
                                        yPadding: 10,
                                        xPadding: 10,
                                        footerMarginTop: 0,
                                        displayColors: false,
                                    },
                                    scales: {
                                        yAxes: [
                                            {
                                                display: true,
                                                stacked: false,
                                                ticks: {
                                                    beginAtZero: true,
                                                    fontSize: 12,
                                                    fontColor: "#9eaecf",
                                                    padding: 5,
                                                },
                                                gridLines: {
                                                    tickMarkLength: 0,
                                                },
                                            },
                                        ],
                                        xAxes: [
                                            {
                                                display: true,
                                                stacked: false,
                                                ticks: {
                                                    fontSize: 12,
                                                    fontColor: "#9eaecf",
                                                    source: "auto",
                                                    padding: 5,
                                                },
                                                gridLines: {
                                                    color: "transparent",
                                                    tickMarkLength: 10,
                                                    zeroLineColor: "transparent",
                                                },
                                            },
                                        ],
                                    },
                                }}
                            />

                        </div>



                    </div>
                </div>
            </div>
        </React.Fragment>)
    }

};
export default PortfolioDrawdown;
