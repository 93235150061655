import React, { useState } from "react";
import axios from "../../../../api/axios";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "reactstrap";

const DELETEMAPPING = "/api/rtm/system_accounts/";
const TradingSystemsTable = ({ data, referch }) => {
  const navigate = useNavigate();
  const [portfolionames, setportfolioNames] = useState(
    JSON.parse(localStorage.getItem("portfolios"))
  );
  const [accountnames, setaccountnames] = useState(
    JSON.parse(localStorage.getItem("accounts"))
  );

  const [deleting, setDeleting] = useState(null);

  const clickHandler = async (state) => {
    setDeleting(state);
    try {
      var token = "";
      if (typeof window !== "undefined") {
        token = localStorage.getItem("Token");
      }

      const response = await axios.delete(DELETEMAPPING + state, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: ["Bearer ", token].join(""),
        },
      });

      var payments_tmp = data;
      payments_tmp.splice(
        payments_tmp.findIndex((a) => a.mapping_id === state),
        1
      );
      data = payments_tmp;

      await referch();
      setDeleting(null);
    } catch (err) {
      console.log(err);
    }
  };

  const renderActiveAlertsHeader = () => {
    let headerElement = [
      "Account",
      "Name",
      "AUM",
      "Profit Factor",
      "AVG DD",
      "MAX DD",
      "AVG Return monthly",
      "Delete",
    ];

    return headerElement.map((key, index) => {
      return <th key={index}>{key.toUpperCase()}</th>;
    });
  };

  function currencyFormat(amount) {
    return amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
  }
  const renderActiveAlertsBody = () => {
    return (
      data &&
      data.map(
        ({
          mapping_id,
          trading_account_id,
          account_name,
          account_balance,
          profit_factor,
          avg_dd,
          max_dd,
          avg_return_monthly,
        }) => {
          return (
            <tr key={mapping_id}>
              <td>
                <Link to={`/accounts/${trading_account_id}`}>
                  {trading_account_id}
                </Link>
              </td>
              <td>{account_name}</td>
              <td>{currencyFormat(account_balance)}</td>
              <td>{profit_factor.toFixed(2)}</td>
              <td>{(avg_dd * 100).toFixed(2)}</td>
              <td>{(max_dd * 100).toFixed(2)}</td>
              <td>{(avg_return_monthly * 100).toFixed(2)}</td>

              <td>
                <Button
                  disabled={deleting == mapping_id}
                  onClick={(e) => {
                    e.preventDefault();
                    clickHandler(mapping_id);
                  }}
                  id={mapping_id}
                >
                  {deleting === mapping_id ? "Deleting..." : "Delete"}
                </Button>
              </td>
            </tr>
          );
        }
      )
    );
  };

  return (
    <div className="card-inner">
      <div className="table-responsive">
        <table className="table border">
          <thead>
            <tr>{renderActiveAlertsHeader()}</tr>
          </thead>
          <tbody>{renderActiveAlertsBody()}</tbody>
        </table>
      </div>
    </div>
  );
};

export default TradingSystemsTable;
