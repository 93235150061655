import React, { useState, useEffect, useContext } from "react";
import axios from "../../../../api/axios";
import { Alert } from "reactstrap";
import Icon from "../../../../assets/components/icon/Icon";
import { Context } from "../../Context";

const NEWTRADER = "/api/tp/trader";

const Form_newTrader = () => {
  const [ShowForm, setShowForm, id, setCurrentStep, setpreSelectedTrader] = useContext(Context);
  const [TraderName, setTraderName] = useState("");
  const [TraderEmail, setTraderEmail] = useState("");
  const [TraderDescription, setTraderDescription] = useState("");
  const [TraderFTTAccount, setTraderFTTAccount] = useState("");
  const [TraderRegistered, setTraderRegistered] = useState("");
  const [errMsg, setErrMsg] = useState("");

  useEffect(() => {
    setErrMsg("");
  }, [
    TraderRegistered,
    TraderFTTAccount,
    TraderDescription,
    TraderEmail,
    TraderName,
  ]);

  const create_trader = async (e) => {
    e.preventDefault();

    if (
      TraderName != "" &&
      TraderEmail != "" &&
      TraderDescription != "" &&
      TraderFTTAccount != "" &&
      TraderRegistered
    ) {
      try {
        var token = "";
        let tmp_trader_list = JSON.parse(localStorage.getItem("trader"));
        if (typeof window !== "undefined") {
          token = localStorage.getItem("Token");
        }
        const response = await axios.post(
          NEWTRADER,
          {
            ftt_account_id: TraderFTTAccount,
            trader_name: TraderName,
            trader_email: TraderEmail,
            registered_at: TraderRegistered,
            trader_description: TraderDescription,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: ["Bearer ", token].join(""),
            },
          }
        );

        let tmp_data = await response.data;

        if (tmp_data != undefined) {
          setpreSelectedTrader(tmp_data.trader_id)
          tmp_trader_list.push(tmp_data);
          localStorage.setItem("trader", JSON.stringify(tmp_trader_list));
        }
        //{"trader_id":1444,"ftt_account_id":0,"trader_name":"Stefan edited","trader_email":"stefan new","registered_at":"2022-11-15","trader_description":"neue Beschreibung"}

        //localStorage.setItem("trader", tmp_trader_list.push(JSON.stringify(response.data)));

        document.getElementById("UserID").value = "";
        document.getElementById("UserName").value = "";
        document.getElementById("TraderDescription").value = "";
        document.getElementById("fttaccountid").value = "";
        document.getElementById("traderregisteredat").value = "";
        setTraderName("");
        setTraderEmail("");
        setTraderDescription("");
        setTraderFTTAccount(null);
        setTraderRegistered("");
        setCurrentStep(id + 1);
      } catch (err) {
        console.log(err);
      }
    } else {
      setErrMsg("Please Fill out all Fields!");
    }
  };

  return (
    <React.Fragment>
      <div className="card-inner">
        <span className="preview-title-lg overline-title">
          Create Trader Profile
        </span>
        <form
          action="src/pages/Trader_performance/Components/CreateTraderSystem/FormCreateTrader#"
          onSubmit={create_trader}
        >
          <div className="row gy-4">
            <div className="col-lg-12">
              <div className="form-group">
                <label className="form-label" htmlFor="phone-no-1">
                  Name
                </label>
                <div className="form-control-wrap">
                  <input
                    type="text"
                    className="form-control"
                    id="UserID"
                    placeholder="Trader Name..."
                    onChange={(e) => setTraderName(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="form-group">
                <label className="form-label" htmlFor="phone-no-1">
                  Email
                </label>
                <div className="form-control-wrap">
                  <input
                    type="email"
                    className="form-control"
                    id="UserName"
                    placeholder="Email..."
                    onChange={(e) => setTraderEmail(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row gy-4">
            <div className="col-lg-12">
              <div className="form-group">
                <label className="form-label" htmlFor="phone-no-1">
                  Description
                </label>
                <div className="form-control-wrap">
                  <textarea
                    className="form-control no-resize"
                    name="message"
                    id="TraderDescription"
                    onChange={(e) => setTraderDescription(e.target.value)}
                    placeholder="Trader Description..."
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="row gy-4">
            <div className="col-lg-12">
              <div className="form-group">
                <label className="form-label" htmlFor="phone-no-1">
                  FTT Account ID
                </label>
                <div className="form-control-wrap">
                  <input
                    type="number"
                    step="1"
                    className="form-control"
                    id="fttaccountid"
                    placeholder="FTT Account Number..."
                    onChange={(e) => setTraderFTTAccount(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="form-group">
                <label className="form-label" htmlFor="phone-no-1">
                  Registered at
                </label>
                <div className="form-control-wrap">
                  <input
                    type="date"
                    className="form-control"
                    id="traderregisteredat"
                    onChange={(e) => setTraderRegistered(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row gy-4">
            <div className="col-lg-6">
              <div className="form-group">
                <label className="form-label" htmlFor="phone-no-1"></label>
                <div className="form-control-wrap">
                  <button className="btn btn-primary" type="submit">
                    Create Trader Profile
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="row gy-4">
            {errMsg && (
              <div className="col-lg-12">
                <div className="form-group">
                  <div className="form-control-wrap">
                    <Alert color="danger" className="alert-icon">
                      {" "}
                      <Icon name="alert-circle" /> {errMsg}
                    </Alert>
                  </div>
                </div>
              </div>
            )}
          </div>
        </form>
      </div>
    </React.Fragment>
  );
};

export default Form_newTrader;
