import React from "react";
import TradingSystemsTable from "./TradingSystemsListTable";

const TradingSystemsList = ({ systemslist }) => {
  return (
    <React.Fragment>
      <div className="card-inner">
        <TradingSystemsTable data={systemslist}></TradingSystemsTable>
      </div>
    </React.Fragment>
  );
};

export default TradingSystemsList;
