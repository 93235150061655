const menudata = [
    {
        icon: "home",
        text: "Home",
        link: "/",
    },
    {
        icon: "coins",
        text: "Trading Analysis",
        active: false,
        subMenu: [
            {
                text: "Accounts",
                link: "/accounts",
            },
            {
                text: "Portfolios",
                link: "/portfolios",
            },
        ],
    },
    //{
    //    icon: "user",
    //    text: "IB Management",
    //    link: "/ib-management",
    //},
    //{
    //    icon: "growth",
    //    text: "Trader Performance",
    //    link: "/trader-performance",
    //},
];
export default menudata;
