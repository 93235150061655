import React, { useState, useEffect } from "react";
import { BalanceEquityLineChart } from "./BalanceChart";
import { useParams } from "react-router-dom";
import moment from 'moment';
import Icon from '../../../../assets/components/icon/Icon';
import axios from "../../../../api/axios";
const BALANCE_HIST_TODAY = '/api/rtm/balance_hist_today/';
const BALANCE_HIST_7DAYS = '/api/rtm/balance_hist_7days/';
const BALANCE_HIST_30DAYS = '/api/rtm/balance_hist_30days/';

const BalanceOverview = () => {
    const [auOverview, setAuOverview] = useState("day");
    const [chart_x_label1, setChartxlabel1] = useState("01 Jan, 2020");
    const [chart_x_label2, setChartxlabel2] = useState("01 Jan, 2020");
    const [chart_x_label3, setChartxlabel3] = useState("01 Jan, 2020");
    const [chartdata, setChartData] = useState({})
    const [isLoading, setLoading] = useState(true);
    const [responseStatus, setResponseStatus] = useState(0);
    const params = useParams()

    const datasetsDefaults = {
        color: "#798bff",
        borderWidth: 2,
        lineTension: 0,
        dash: 0,
        borderColor: "#9d72ff",
        backgroundColor: "rgba(121, 139, 255, 0.15)",
        borderCapStyle: "square",
        pointBorderColor: "transparent",
        pointBackgroundColor: "transparent",
        pointHoverBackgroundColor: "#fff",
        pointHoverBorderColor: "#9d72ff",
        pointBorderWidth: 2,
        pointHoverRadius: 4,
        pointHoverBorderWidth: 2,
        pointRadius: 4,
        pointHitRadius: 4
    }

    useEffect(() => {
        async function fetchBalanceEquityData() {
            try {
                var token = "";
                if (typeof window !== 'undefined') {
                    token = localStorage.getItem('Token');
                }

                const responsetoday = await axios.get(BALANCE_HIST_TODAY + params.userId, {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Authorization': ['Bearer ', token].join('')
                    }
                });

                const responseweek = await axios.get(BALANCE_HIST_7DAYS + params.userId, {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Authorization': ['Bearer ', token].join('')
                    }
                });

                const responsemonth = await axios.get(BALANCE_HIST_30DAYS + params.userId, {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Authorization': ['Bearer ', token].join('')
                    }
                })


                let equitydata = {
                    day: {
                        labels: responsetoday?.data.map(a => moment(a.timestamp).format('LLL')),
                        dataUnit: "People",
                        datasets: [
                            {
                                label: "Equity",
                                ...datasetsDefaults,
                                data: responsetoday?.data.map(a => a.equity),
                            },
                            {
                                label: "Balance",
                                ...datasetsDefaults,
                                data: responsetoday?.data.map(a => a.balance),
                            },
                        ],
                    },
                    week: {
                        labels: responseweek?.data.map(a => moment(a.timestamp).format('LLL')),
                        dataUnit: "People",
                        datasets: [
                            {
                                label: "Equity",
                                ...datasetsDefaults,
                                data: responseweek?.data.map(a => a.equity),
                            },
                            {
                                label: "Balance",
                                ...datasetsDefaults,
                                data: responseweek?.data.map(a => a.balance),
                            },
                        ],
                    },
                    month: {
                        labels: responsemonth?.data.map(a => moment(a.timestamp).format('LLL')),
                        dataUnit: "People",
                        datasets: [
                            {
                                label: "Equity",
                                ...datasetsDefaults,
                                data: responsemonth?.data.map(a => a.equity),
                            },
                            {
                                label: "Balance",
                                ...datasetsDefaults,
                                data: responsemonth?.data.map(a => a.balance),
                            },
                        ],
                    }
                }

                setChartData(equitydata);
                setResponseStatus(responsemonth?.status);


            }
            catch (e) {
                console.log(e);
            }

        }

        fetchBalanceEquityData();

    }, [auOverview]);

    useEffect(() => {
        if (responseStatus !== 200) return

        setLoading(false)
        
    }, [responseStatus]);


    if (isLoading) return <></>

    return (
        <React.Fragment>
            <div className="card-title-group pb-3 g-2">
                <div className="card-title card-title-sm">
                    <h6 className="title">Equity View</h6>
                    <p>View the Balance and Equity development.</p>
                </div>
                <div className="card-tools shrink-0 d-none d-sm-block">
                    <ul className="nav nav-switch-s2 nav-tabs bg-white">
                        <li className="nav-item">
                            <a
                                href="#navitem"
                                className={auOverview === "day" ? "nav-link active" : "nav-link"}
                                onClick={(e) => {
                                    e.preventDefault();
                                    setAuOverview("day");
                                    setChartxlabel1("27.05 00:00")
                                    setChartxlabel2("27.05 12:00")
                                }}
                            >
                                1 D
                            </a>
                        </li>
                        <li className="nav-item">
                            <a
                                href="#navitem"
                                className={auOverview === "week" ? "nav-link active" : "nav-link"}
                                onClick={(e) => {
                                    e.preventDefault();
                                    setAuOverview("week");
                                }}
                            >
                                7 D
                            </a>
                        </li>
                        <li className="nav-item">
                            <a
                                href="#navitem"
                                className={auOverview === "month" ? "nav-link active" : "nav-link"}
                                onClick={(e) => {
                                    e.preventDefault();
                                    setAuOverview("month");
                                }}
                            >
                                1 M
                            </a>
                        </li>
                    </ul>
                </div>
            </div>

            <div className="analytic-ov">

                <div className="analytic-ov-ck">
                    <BalanceEquityLineChart chartdata={chartdata[auOverview]} />
                </div>
                <div className="chart-label-group ml-5">
                    <div className="chart-label"></div>
                    <div className="chart-label d-none d-sm-block"></div>
                    <div className="chart-label"></div>
                </div>
            </div>
        </React.Fragment>
    );

};
export default BalanceOverview;
