import React, { useState, Outlet, useEffect } from "react";
import { NavLink, Link, useParams, useNavigate } from "react-router-dom";
import Head from '../../../components/head/Head';
import Content from '../../../components/content/Content';
import axios from "../../../api/axios";
import moment from 'moment';
import {
    Block,
    BlockDes,
    BlockHead,
    BlockHeadContent,
    BlockBetween,
    BlockTitle,
    PreviewAltCard,
    Icon,
    Button,
    Row,
    Col,
    TooltipComponent
} from "../../../assets/components/Component";
import { Card, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import FileUploadcomponent from "../Components/FileUpload/Fileupload";
import TraderDetails from "../Components/TraderDetails/TraderDetails";
import SystemComments from "../Components/TradingSystemComments/CommentSection";
import SystemDetails from "../Components/TradingSystemDetails/TradingSystemDetails";
import KnowYourTrader from "../Components/KnowYourTrader/KnowYourTrader";
import BrokerDetails from "../Components/BrokerageDetails/BrokerDetails";
import KnowYourTraderChart from "../Components/KnowYourTraderChart/KnowYourTraderChart";

const SpecificTradingSystem = () => {
    const navigate = useNavigate();
    const params = useParams()
    const [tradingsystems, setTradingsystems] = useState(JSON.parse(localStorage.getItem("trading_systems_list")));
    const [traderall, setTraderall] = useState(JSON.parse(localStorage.getItem("trader")));
    const [traderselected, setTraderselected] = useState({});
    const [roles, setRoles] = useState(localStorage.getItem('Roles'));


    useEffect(() => {
        const filtered = traderall.filter(item => {
            return item.trader_id === tradingsystems[params.tradingsystem].trader_id;
        });
        setTraderselected(filtered[0]);
    }, []);

    console.log(traderselected)
    return (
        <React.Fragment>
            <Head title="Portfolio Details" />
            <Content>
                <BlockHead size="sm">
                    <BlockBetween>
                        <BlockHeadContent>
                            <BlockTitle page>System {params.tradingsystem} - {tradingsystems[params.tradingsystem].system_name}</BlockTitle>
                            <div className="nk-block-head-sub"><a className="back-to" href="/trader-performance"
                                                                  onClick={(ev) => {
                                                                      ev.preventDefault();
                                                                      navigate(-1);
                                                                  }}>
                                <em className="icon ni ni-arrow-left"></em><span>Trader Performance</span></a></div>
                        </BlockHeadContent>
                    </BlockBetween>
                </BlockHead>

                <Block>
                    <Row className="g-gs">
                        <Col md="7" xxl="7">
                            <PreviewAltCard className="card-full">
                                <TraderDetails></TraderDetails>
                            </PreviewAltCard>
                        </Col>
                        <Col md="5" xxl="5">
                            <PreviewAltCard className="card-full">
                                <FileUploadcomponent></FileUploadcomponent>
                            </PreviewAltCard>
                        </Col>
                    </Row>

                    <Row className="g-gs">
                        <Col md="7" xxl="7">
                            <PreviewAltCard className="card-full">

                                <SystemDetails></SystemDetails>
                            </PreviewAltCard>
                        </Col>
                        <Col md="5" xxl="5">
                            <PreviewAltCard className="card-full">

                                <SystemComments/>

                            </PreviewAltCard>
                        </Col>

                    </Row>


                                <KnowYourTrader></KnowYourTrader>






                </Block>
            </Content>
        </React.Fragment>
    )

};

export default SpecificTradingSystem;