import React, {useState, useEffect} from 'react'
import axios from "../../../../api/axios";
import {Alert} from "reactstrap";
import Icon from "../../../../assets/components/icon/Icon";
import {useParams} from "react-router-dom";
import moment from "moment";

const FTT_RETURN = '/api/rtm/ftt_return_account/';

const Ftt_account_return_table = () => {
    const params = useParams()
    const [activeAlerts, setactiveAlerts] = useState([]);
    const [AllowedSymbols, setAllowedSymbols] = useState([]);
    const [headline, setHeadline] = useState("Investor Statistic");
    const [fttusers, setFttUsers] = useState(JSON.parse(localStorage.getItem('FTTUser')))
    const [roles, setRoles] = useState(localStorage.getItem('Roles'));
    const [datatime, setdatatime] = useState();


    useEffect(() => {
        async function fetchFTTReturnAccount() {
            try {
                var token = "";
                if (typeof window !== 'undefined') {
                    token = localStorage.getItem('Token');
                }
                const response = await axios.get(FTT_RETURN + params.userId, {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Authorization': ['Bearer ', token].join('')
                    }
                });

                if (await response?.data.length > 0)
                {
                    if (fttusers.length > 0) {
                        let newData = [];

                        for (let value in fttusers) {

                            let filteredData = await response?.data.filter(
                                item =>
                                    item.client_id.toString().toLowerCase().includes(fttusers[value])
                            );

                            for (let idx in filteredData) {
                                newData.push(filteredData[idx]);
                            }
                            ;
                        }
                        setAllowedSymbols(newData);
                        setHeadline("Your Personal Statistic");
                    } else {
                        setAllowedSymbols(await response?.data);

                    }
                    setdatatime(moment(await response?.data[0]["run_date"]).format('LLL'));
                }

            } catch (e) {
                console.log(e);
            }
        }


        fetchFTTReturnAccount();

    }, [])


    function currencyFormat(amount) {
        return (amount).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    }

    const renderallowedSymbolHeader = () => {
        let headerElement = ['Type', 'Name', 'Company', 'Account Balance', 'Invested Amount', 'Return %']

        return headerElement.map((key, index) => {
            return <th key={index}>{key.toUpperCase()}</th>
        })
    }
    const renderallowedSymbolBody = () => {
        return AllowedSymbols && AllowedSymbols.map(({
                                                         client_id,
                                                         account_type,
                                                         name,
                                                         company,
                                                         balance,
                                                         invested_amount,
                                                         roi_perc
                                                     }) => {
            return (
                <tr key={"id" + Math.random().toString(16).slice(2)}>
                    <td>{account_type}</td>
                    <td>{name}</td>
                    <td>{company}</td>
                    <td>{currencyFormat(balance)}</td>
                    <td>{currencyFormat(invested_amount)}</td>
                    <td>{roi_perc.toFixed(2)}</td>

                </tr>
            )
        })
    }


    if (AllowedSymbols.length > 0) {
        return (
            <React.Fragment>
                <div className="card-inner">
                    <div className="card-title-group">
                        <div className="card-title card-title-sm">
                            <h6 className="title">{headline}</h6>
                        </div>
                        <div className="card-tools">
                            <ul className="card-tools-nav">
                                <li>
                                    <span>Last updated: {datatime} (updated Daily)</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="card-inner">


                    <table className="table" id='allowed_symbols'>
                        <thead>
                        <tr>{renderallowedSymbolHeader()}</tr>
                        </thead>
                        <tbody>
                        {renderallowedSymbolBody()}
                        </tbody>
                    </table>

                </div>
            </React.Fragment>
        )
    }
}


export default Ftt_account_return_table