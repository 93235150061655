import React, { useState, Outlet } from "react";
import Head from '../../components/head/Head';
import Content from '../../components/content/Content';
import ChartOverview from './components/FTT_Return_Table/ftt_portfolio_return_chart_overview'


import { Card, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import {
    Block,
    BlockDes,
    BlockHead,
    BlockHeadContent,
    BlockBetween,
    BlockTitle,
    PreviewAltCard,
    Icon,
    Button,
    Row,
    Col,
} from "../../assets/components/Component";

import PortfolioTable from "./components/Portfolio_Table/Portfolio_table";

const Portfolio_List = () => {
    const [sm, updateSm] = useState(false);
    return (
        <React.Fragment>
            <Head title="RTM - Portfolios" />
            <Content>
                <BlockHead size="sm">
                    <BlockBetween>
                        <BlockHeadContent>
                            <BlockTitle page>Portfolios</BlockTitle>
                            <BlockDes className="text-soft">
                                <p>Please select one Portfolio</p>
                            </BlockDes>
                        </BlockHeadContent>
                        <BlockHeadContent>
                            {/*
                            <div className="toggle-wrap nk-block-tools-toggle">
                                <Button
                                    className={`btn-icon btn-trigger toggle-expand mr-n1 ${sm ? "active" : ""}`}
                                    onClick={() => updateSm(!sm)}
                                >
                                    <Icon name="more-v" />
                                </Button>
                                <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                                    <ul className="nk-block-tools g-3">

                                        <li className="nk-block-tools-opt">
                                            <UncontrolledDropdown>
                                                <DropdownToggle color="transparent" className="btn btn-primary btn-icon dropdown-toggle">
                                                    <Icon name="plus"></Icon>
                                                </DropdownToggle>
                                                <DropdownMenu right>
                                                    <ul className="link-list-opt no-bdr">
                                                        <li>
                                                            <DropdownItem
                                                                tag="a"
                                                                href="#dropdownitem"
                                                                onClick={(ev) => {
                                                                    ev.preventDefault();
                                                                }}
                                                            >
                                                                <Icon name="user-add-fill"></Icon>
                                                                <span>Add Portfolio</span>
                                                            </DropdownItem>
                                                        </li>

                                                    </ul>
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            */}
                        </BlockHeadContent>
                    </BlockBetween>
                </BlockHead>
                <Block>
                    <Row className="g-gs">
                        <Col lg="12">
                            <Card className="card-bordered card-full">
                                <PortfolioTable />
                            </Card>
                        </Col>

                    </Row>
                </Block>
            </Content>

        </React.Fragment>
    );
};

export default Portfolio_List;
