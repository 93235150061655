import React, { useState, useEffect, useContext } from "react";
import axios from "../../../../api/axios";
import { Alert } from "reactstrap";
import Icon from "../../../../assets/components/icon/Icon";
import { Context } from "../../Context";

const TRADINGSYSTEM = "/api/tp/tradingsystem";
const SYSTEMMAPPING = "/api/tp/tradingsystem_mapping";

const Form_newSystem = ({ text }) => {
  const [
    ShowForm,
    setShowForm,
    setSystemID,
    id,
    setCurrentStep,
    preSelectedTrader,
  ] = useContext(Context);
  const [TraderList, setTraderList] = useState(
    JSON.parse(localStorage.getItem("trader"))
  );
  const [Traderselected, setTraderselected] = useState(preSelectedTrader);

  const [SystemCategories, setSystemCategories] = useState([]);
  const [SystemTypeText, setSystemTypeText] = useState("");

  const [TradingSystem, setTradingSystem] = useState("");
  const [SystemDescription, setSystemDescription] = useState("");

  const [errMsg, setErrMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");

  console.log(TraderList);

  useEffect(() => {
    setErrMsg("");
  }, [
    TradingSystem,
    SystemCategories,
    SystemDescription,
    SystemTypeText,
    Traderselected,
  ]);

  const create_tradingsystem = async (e) => {
    e.preventDefault();

    if (
      TradingSystem != "" &&
      SystemCategories.toString() != "" &&
      SystemDescription != "" &&
      SystemTypeText != "" &&
      Traderselected
    ) {
      try {
        var token = "";
        if (typeof window !== "undefined") {
          token = localStorage.getItem("Token");
        }
        const response = await axios.post(
          TRADINGSYSTEM,
          {
            trader_id: Traderselected,
            system_name: TradingSystem,
            system_description: SystemDescription,
            system_type: SystemTypeText,
            product_category: SystemCategories.toString(),
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: ["Bearer ", token].join(""),
            },
          }
        );

        console.log(JSON.stringify(await response));
        setSystemID(await response?.data?.system_id);
        document.getElementById("UserID").value = "";
        document.getElementById("TraderDescription").value = "";
        document.getElementById("systemtypetext").value = "";
        setSuccessMsg(
          "Trading System has been created. Please Select the corresponding Trading Accounts below."
        );
        setCurrentStep(id + 1);
      } catch (err) {
        console.log(err);
        setErrMsg("Something went wrong! Please Try again later.");
      }
    } else {
      setErrMsg("Please Fill out all Fields!");
    }
  };

  const handleChangesystemcategory = (e) => {
    var selected = [];
    for (var option of document.getElementById("selectsystemcategory")
      .options) {
      if (option.selected) {
        selected.push(option.value);
      }
    }
    setSystemCategories(selected);
  };

  return (
    <React.Fragment>
      <div className="card-inner">
        <span className="preview-title-lg overline-title">
          Create Tradingsystem {text}
        </span>
        <form
          action="src/pages/Trader_performance/Components/CreateTraderSystem/FormCreateSystem#"
          onSubmit={create_tradingsystem}
        >
          <div className="row gy-4">
            <div className="col-lg-12">
              <div className="form-group">
                <label className="form-label" htmlFor="phone-no-1">
                  System Name
                </label>
                <div className="form-control-wrap">
                  <input
                    type="text"
                    className="form-control"
                    id="UserID"
                    placeholder="System Name..."
                    onChange={(e) => setTradingSystem(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="form-group">
                <label className="form-label" htmlFor="phone-no-1">
                  Trader
                </label>
                <div className="form-control-wrap">
                  <select
                    className="form-control"
                    id="selectTrader"
                    onChange={(e) => setTraderselected(e.target.value)}
                    defaultValue={Traderselected}
                  >
                    {TraderList.map((key) => {
                      return (
                        <option key={key.trader_id} value={key.trader_id}>
                          {key.trader_name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="row gy-4">
            <div className="col-lg-12">
              <div className="form-group">
                <label className="form-label" htmlFor="phone-no-1">
                  System Description
                </label>
                <div className="form-control-wrap">
                  <textarea
                    className="form-control no-resize"
                    name="message"
                    id="TraderDescription"
                    onChange={(e) => setSystemDescription(e.target.value)}
                    placeholder="System Description..."
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="row gy-4">
            <div className="col-lg-12">
              <div className="form-group">
                <label className="form-label" htmlFor="phone-no-1">
                  Trading System Product Category
                </label>
                <div className="form-control-wrap">
                  <div className="form-control-wrap">
                    <div className="form-control-select-multiple">
                      <select
                        className="custom-select"
                        type="select"
                        name="select"
                        id="selectsystemcategory"
                        multiple
                        onChange={(e) =>
                          handleChangesystemcategory(e.target.value)
                        }
                      >
                        <option key="Institutionell" value="Institutionell">
                          Institutionell
                        </option>
                        <option key="Retail" value="Retail">
                          Retail
                        </option>
                        <option key="HNWI" value="HNWI">
                          HNWI
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="form-group">
                <label className="form-label" htmlFor="phone-no-1">
                  Trading System Type Category
                </label>
                <div className="form-control-wrap">
                  <input
                    type="text"
                    className="form-control"
                    id="systemtypetext"
                    placeholder="System Type (Swing, Scalping)..."
                    onChange={(e) => setSystemTypeText(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="row gy-4">
            <div className="col-lg-6">
              <div className="form-group">
                <label className="form-label" htmlFor="phone-no-1"></label>
                <div className="form-control-wrap">
                  <button className="btn btn-primary" type="submit">
                    Create Tradingsystem
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="row gy-4">
            <div className="col-lg-12">
              {errMsg && (
                <div className="form-group">
                  <div className="form-control-wrap">
                    <Alert color="danger" className="alert-icon">
                      {" "}
                      <Icon name="alert-circle" /> {errMsg}
                    </Alert>
                  </div>
                </div>
              )}
              {successMsg && (
                <div className="form-group">
                  <div className="form-control-wrap">
                    <Alert color="success" className="alert-icon">
                      {" "}
                      <Icon name="done" /> {successMsg}
                    </Alert>
                  </div>
                </div>
              )}
            </div>
          </div>
        </form>
      </div>
    </React.Fragment>
  );
};

export default Form_newSystem;
